import React, { useCallback } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import { Subscribe } from 'unstated';

import AutomationIcon from '@atlaskit/icon/core/automation';
import { useAnalyticsEvents, type CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import ShapesIcon from '@atlaskit/icon/core/shapes';
import PersonIcon from '@atlaskit/icon/core/person';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import AppsIcon from '@atlaskit/icon/core/apps';
import { Inline, xcss } from '@atlaskit/primitives';
import ArchiveBoxIcon from '@atlaskit/icon/core/archive-box';
import CopyIcon from '@atlaskit/icon/core/copy';
import ChangesIcon from '@atlaskit/icon/core/changes';
import Toggle from '@atlaskit/toggle';
import UploadIcon from '@atlaskit/icon/core/upload';
import FolderOpenIcon from '@atlaskit/icon/core/folder-open';
import QuotationMarkIcon from '@atlaskit/icon/core/quotation-mark';
import DeleteIcon from '@atlaskit/icon/core/delete';
import ShowMoreIcon from '@atlaskit/icon/utility/show-more-horizontal';

import { fg } from '@confluence/feature-gating';
import { ANALYTICS_ADDON_ACTIONS_MENU_ID, CONNECT_APP_PREFIX_KEY } from '@confluence/web-item';
import { LazyForgeContentTools } from '@confluence/content-tools';
import { AUTOMATION_MANUAL_TRIGGERS } from '@confluence/experience-tracker';
import { PageQuickActionAutomationMenu } from '@confluence/automation-menu/entry-points/PageQuickActionAutomationMenu';
import { ContentOwnerPopup } from '@confluence/content-ownership';
import { ViewChangesDialogLoader } from '@confluence/view-changes-dialog';
import { DialogsStateContainer } from '@confluence/dialogs';
import {
	ConvertToFolderItem,
	LazyContentToolsItem,
} from '@confluence/content-tools/entry-points/ContentToolsButton';
import {
	PublishPageAsBlogDialog,
	PublishPageAsBlogSources,
} from '@confluence/publish-page-as-blog';

import {
	ACTION_EXPORT_PDF,
	ACTION_EXPORT_WORD,
	ACTION_ARCHIVE_CONTENT,
	ACTION_REMOVE_CONTENT,
	ACTION_CONVERT_TO_BLOG,
	ACTION_CONVERT_TO_FOLDER,
	SWITCH_TO_LIVE_EDIT_WEB_ITEM,
	ACTION_COPY_CONTENT,
	ACTION_EXPORT_CONTENT,
	ACTION_CONVERT_CONTENT,
	ACTION_VIEW_STORAGE_FORMAT,
} from '../MoreActions';

import { ConvertToLivePageItem } from './ConvertToLivePageMenuItem';
import { ExpandableNestedMenuItem } from './ExpandableNestedMenuItem';
export const ACTION_APPS = 'action-apps';

const shortcutStyles = xcss({
	backgroundColor: 'color.background.neutral',
	color: 'color.text.accent.gray',
	paddingBlock: 'space.025',
	paddingInline: 'space.075',
	borderRadius: '2px',
	margin: 'space.025',
});

export const i18n = defineMessages({
	export: {
		id: 'content-types-header.ellipsis.open.ellipsis.export',
		description:
			'The text that is displayed in the menu dropdown that groups Export to PDF and Export to Word',
		defaultMessage: 'Export',
	},
	convert: {
		id: 'content-types-header.ellipsis.open.ellipsis.convert',
		description:
			'The text that is displayed in the menu dropdown that groups convert to other content type actions',
		defaultMessage: 'Convert',
	},
	copy: {
		id: 'content-types-header.ellipsis.open.ellipsis.copy',
		description:
			'The text that is displayed in the menu dropdown that groups Copy Database and Copy structure actions',
		defaultMessage: 'Copy',
	},
	copyDatabase: {
		id: 'content-types-header.ellipsis.open.ellipsis.copy.database',
		description: 'Label for copying database action',
		defaultMessage: 'Copy database',
	},
	archive: {
		id: 'content-types-header.ellipsis.open.ellipsis.archive',
		description: 'The text that is displayed in the menu dropdown that groups Archive and Delete',
		defaultMessage: 'Archive',
	},
	deleteDraftLabel: {
		id: 'content-types-header.content-types.more-actions.delete-draft',
		defaultMessage: 'Delete draft',
		description: 'Label for the delete draft action',
	},
	deleteLabel: {
		id: 'content-types-header.content-types.more-actions.delete',
		defaultMessage: 'Delete',
		description: 'Label for the delete action',
	},
	archiveAndDeleteLabel: {
		id: 'content-types-header.content-types.more-actions.archive-and-delete',
		defaultMessage: 'Archive and delete',
		description: 'Label for the archive and delete action',
	},
	viewChangesTitle: {
		id: 'content-types-header.content-types.view.changes.title',
		description: 'heading of view changes dialog in a live page',
		defaultMessage: 'Latest changes',
	},
	viewChangesLabel: {
		id: 'content-types-header.content-types.view.changes.label',
		description: 'The label of View changes button inside ellipsis dropdown of the editor',
		defaultMessage: 'View changes',
	},
	automationsModalTitle: {
		id: 'content-types-header.automations.title',
		defaultMessage: 'Page automations',
		description: 'The title of the page automation modal header.',
	},
	automationsLabel: {
		id: 'content-types-header.automations.label',
		defaultMessage: 'Automations',
		description: 'The label of the Automations button inside the ellipsis dropdown',
	},
	advancedLabel: {
		id: 'content-types-header.advanced.label',
		defaultMessage: 'Advanced details',
		description: 'The label of the Advanced details button inside the ellipsis dropdown',
	},
	pageAppsLabel: {
		id: 'content-types-header.ellipsis.open.ellipsis.page.apps',
		defaultMessage: 'Apps',
		description:
			'The text in dropdown menu that displays all the apps that are available for the page',
	},
});

export enum NestedMenuTypes {
	Export = 'export',
	Convert = 'convert',
	Archive = 'archive',
	Advanced = 'advanced',
	Attachments = 'attachments',
	Apps = 'apps',
	ContentOwnership = 'content-ownership',
	MoreSupport = 'more-support',
	CopyDatabase = 'copy-database',
	Help = 'help',
	Watch = 'watch',
}

type CustomActionProps = {
	item: {
		id: string;
		text: string;
		shortcutText?: string;
		isDisabled?: boolean;
		showToggle?: {
			isDisabled: boolean;
			isChecked?: boolean;
		};
		onClick?: () => void;
		url?: string;
		target?: string;
	};
	icon?: React.ReactNode;
};

const ConvertMenuItemComponent = ({
	webItemsMap,
	isNestedMenuOpen,
	handleClick,
	spaceKey,
	contentId,
	dialogs,
	handleNestedMenuItemClose,
	contentMode,
	flags,
	spaceId,
	closeParentMenu,
	isConvertToFolderHighlighted,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const convertItems = [
		SWITCH_TO_LIVE_EDIT_WEB_ITEM,
		ACTION_CONVERT_TO_FOLDER,
		ACTION_CONVERT_TO_BLOG,
	]
		.map((id) => {
			const item = webItemsMap[id];
			if (id === ACTION_CONVERT_TO_FOLDER && item) {
				item.url = '';
				item.urlWithoutContextPath = '';
				return {
					...item,
					customComponent: () => (
						<ConvertToFolderItem
							key={item.completeKey}
							sectionItem={item}
							contentId={contentId}
							spaceKey={spaceKey}
							dialogs={dialogs}
							shouldHighlight={isConvertToFolderHighlighted}
							elemBefore={<FolderOpenIcon label="" />}
							onClick={closeParentMenu}
						/>
					),
				};
			} else if (id === ACTION_CONVERT_TO_BLOG && item) {
				item.url = '';
				item.urlWithoutContextPath = '';

				const makePublishPageAsBlogDialogOpener = () => {
					closeParentMenu();
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							action: 'clicked',
							actionSubject: 'button',
							actionSubjectId: 'publishPageAsBlog',
							source: 'contentTypesHeader',
						},
					}).fire();
					dialogs.showDialog(PublishPageAsBlogDialog, {
						contentId,
						spaceKey,
						source: PublishPageAsBlogSources.ViewPage,
					});
				};

				return {
					...item,
					customComponent: () => {
						return (
							<LazyContentToolsItem
								key={item.completeKey}
								{...item}
								onClick={makePublishPageAsBlogDialogOpener}
								elemBefore={<QuotationMarkIcon label="" />}
							/>
						);
					},
				};
			} else if (id === SWITCH_TO_LIVE_EDIT_WEB_ITEM && item) {
				return {
					...item,
					customComponent: () => (
						<ConvertToLivePageItem
							item={item}
							contentMode={contentMode}
							spaceId={spaceId}
							flags={flags}
							spaceKey={spaceKey}
							contentId={contentId}
							closeParentMenu={closeParentMenu}
						/>
					),
				};
			} else {
				return null;
			}
		})
		.filter((item) => item && item.id);

	return (
		<ExpandableNestedMenuItem
			label={<FormattedMessage {...i18n.convert} />}
			items={convertItems}
			isNestedMenuOpen={isNestedMenuOpen}
			onClick={handleClick}
			iconBefore={<ChangesIcon label="" />}
			key={ACTION_CONVERT_CONTENT}
			onMenuClose={handleNestedMenuItemClose}
		/>
	);
};

export const ConvertMenuItem = ({
	webItemsMap,
	nestedPopup,
	handleNestedMenuItemClick,
	spaceKey,
	contentId,
	handleNestedMenuItemClose,
	flags,
	spaceId,
	contentMode,
	closeParentMenu,
	isConvertToFolderHighlighted,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleConvertMenuItemClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'overflowExportAndConvert',
				source: 'overflowMenu',
			},
		}).fire();
		handleNestedMenuItemClick(NestedMenuTypes.Convert);
	};

	return (
		<Subscribe to={[DialogsStateContainer]}>
			{(dialogs) => (
				<ConvertMenuItemComponent
					webItemsMap={webItemsMap}
					dialogs={dialogs}
					isNestedMenuOpen={nestedPopup === NestedMenuTypes.Convert}
					handleClick={handleConvertMenuItemClick}
					contentId={contentId}
					spaceKey={spaceKey}
					flags={flags}
					spaceId={spaceId}
					handleNestedMenuItemClose={handleNestedMenuItemClose}
					contentMode={contentMode}
					closeParentMenu={closeParentMenu}
					isConvertToFolderHighlighted={isConvertToFolderHighlighted}
				/>
			)}
		</Subscribe>
	);
};

export const ExportMenuItem = ({
	webItemsMap,
	handleNestedMenuItemClick,
	nestedPopup,
	handleNestedMenuItemClose,
	closeParentMenu,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const exportItems = [ACTION_EXPORT_WORD, ACTION_EXPORT_PDF]
		.map((id) => {
			const item = webItemsMap[id];
			return {
				...item,
				closeParentMenu,
			};
		})
		.filter((item) => item.id);

	const handleExportMenuItemClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'overflowExport',
				source: 'overflowMenu',
			},
		}).fire();
		handleNestedMenuItemClick(NestedMenuTypes.Export);
	};

	return (
		<ExpandableNestedMenuItem
			label={<FormattedMessage {...i18n.export} />}
			items={exportItems}
			iconBefore={<UploadIcon label="" />}
			isNestedMenuOpen={nestedPopup === NestedMenuTypes.Export}
			onClick={handleExportMenuItemClick}
			key={ACTION_EXPORT_CONTENT}
			onMenuClose={handleNestedMenuItemClose}
		/>
	);
};

export const DeleteMenuItem = ({
	onDeleteClick,
	contentMode,
	createAnalyticsEvent,
}: {
	onDeleteClick: (dialogs: any) => void;
	contentMode?: string;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
}) => {
	const label = contentMode === 'draft' ? i18n.deleteDraftLabel : i18n.deleteLabel;

	return (
		<Subscribe to={[DialogsStateContainer]} key={`container-${ACTION_REMOVE_CONTENT}`}>
			{(dialogs: DialogsStateContainer) => (
				<DropdownItem
					onClick={() => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'button',
								actionSubjectId: 'overflowDelete',
								source: 'overflowMenu',
							},
						}).fire();
						onDeleteClick(dialogs);
					}}
					elemBefore={<DeleteIcon label="" />}
				>
					<FormattedMessage {...label} />
				</DropdownItem>
			)}
		</Subscribe>
	);
};

export const ArchiveMenuItem = ({ onArchiveClick }) => {
	return (
		<Subscribe to={[DialogsStateContainer]} key={`container-${ACTION_ARCHIVE_CONTENT}`}>
			{(dialogs: DialogsStateContainer) => (
				<DropdownItem
					onClick={() => {
						onArchiveClick(dialogs);
					}}
					elemBefore={<ArchiveBoxIcon label="" />}
				>
					<FormattedMessage {...i18n.archive} />
				</DropdownItem>
			)}
		</Subscribe>
	);
};

export const ArchiveDeleteMenuItem = ({
	webItems,
	isNestedMenuOpen,
	handleClick,
	onArchiveClick,
	onDeleteClick,
	contentMode,
	createAnalyticsEvent,
	handleNestedMenuItemClose,
}) => {
	const archiveItems = webItems
		.filter((item) => item.id === ACTION_ARCHIVE_CONTENT || item.id === ACTION_REMOVE_CONTENT)
		.map((item) => {
			if (item.id === ACTION_ARCHIVE_CONTENT) {
				return {
					...item,
					customComponent: () => <ArchiveMenuItem onArchiveClick={onArchiveClick} />,
				};
			} else if (item.id === ACTION_REMOVE_CONTENT) {
				return {
					...item,
					customComponent: () => (
						<DeleteMenuItem
							onDeleteClick={onDeleteClick}
							contentMode={contentMode}
							createAnalyticsEvent={createAnalyticsEvent}
						/>
					),
				};
			}
			return item;
		});

	return (
		<ExpandableNestedMenuItem
			label={<FormattedMessage {...i18n.archiveAndDeleteLabel} />}
			items={archiveItems}
			isNestedMenuOpen={isNestedMenuOpen}
			onClick={handleClick}
			iconBefore={<DeleteIcon label="" />}
			onMenuClose={handleNestedMenuItemClose}
		/>
	);
};

export const ShortcutKeysVisual = ({
	shortcutKeys,
	id,
}: {
	shortcutKeys: string[];
	id: string;
}) => (
	<Inline>
		{shortcutKeys.map((key) => (
			<Inline key={`${id}-shortcut-${key}`} xcss={shortcutStyles}>
				{key}
			</Inline>
		))}
	</Inline>
);

export const CustomAction = ({ item, icon }: CustomActionProps) => {
	const { id, url, target, onClick, shortcutText, text, showToggle, isDisabled } = item;

	const ElementAfter = () => {
		if (shortcutText) {
			return <ShortcutKeysVisual shortcutKeys={shortcutText.split('+')} id={id} />;
		} else if (showToggle) {
			return <Toggle isChecked={showToggle.isChecked} isDisabled={showToggle.isDisabled} />;
		}
		return null;
	};

	return (
		<DropdownItem
			elemBefore={icon ?? null}
			key={id}
			href={url}
			target={target}
			onClick={onClick}
			isDisabled={isDisabled}
			elemAfter={<ElementAfter />}
		>
			{text}
		</DropdownItem>
	);
};

export const CopyMenuItem = ({
	setIsCopyContentModalOpen,
	closeParentMenu,
	item,
	showIcon = true,
}) => (
	<DropdownItem
		key={ACTION_COPY_CONTENT}
		onClick={() => {
			closeParentMenu();
			setIsCopyContentModalOpen(true);
		}}
		elemBefore={showIcon ? <CopyIcon label="" /> : null}
	>
		{item.label}
	</DropdownItem>
);

export const NestedDatabaseCopyMenu = ({
	isNestedMenuOpen,
	handleClick,
	copyDatabaseItem,
	copyStructureItem,
	setIsCopyContentModalOpen,
	contentMode,
	handleNestedMenuItemClose,
	closeParentMenu,
}) => {
	const { formatMessage } = useIntl();
	const copyDatabaseLabel = formatMessage(i18n.copyDatabase);

	const copyOnClick = copyStructureItem.onClick;
	copyStructureItem.onClick = () => {
		closeParentMenu();
		copyOnClick?.();
	};
	if (contentMode === 'archived') {
		return (
			<CustomAction
				item={copyStructureItem}
				key={copyStructureItem.id}
				icon={<CopyIcon label="" />}
			/>
		);
	}

	const copyStructureItemWithComponent = {
		...copyStructureItem,
		customComponent: () => <CustomAction item={copyStructureItem} key={copyStructureItem.id} />,
	};

	const copyItems = [
		{
			...copyDatabaseItem,
			customComponent: () => (
				<CopyMenuItem
					setIsCopyContentModalOpen={setIsCopyContentModalOpen}
					closeParentMenu={closeParentMenu}
					item={{ ...copyDatabaseItem, label: copyDatabaseLabel }}
					showIcon={false}
				/>
			),
		},
		copyStructureItemWithComponent,
	];

	return (
		<ExpandableNestedMenuItem
			label={<FormattedMessage {...i18n.copy} />}
			items={copyItems}
			isNestedMenuOpen={isNestedMenuOpen}
			onClick={handleClick}
			iconBefore={<CopyIcon label="" />}
			onMenuClose={handleNestedMenuItemClose}
		/>
	);
};

export const ViewChangesItem = ({ contentId, dialogs, saveDraft, createAnalyticsEvent }) => {
	const intl = useIntl();

	const onClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'overflowViewChanges',
				source: 'overflowMenu',
			},
		}).fire();
		saveDraft?.();
		dialogs.showModal(ViewChangesDialogLoader, {
			contentId,
			title: intl.formatMessage(i18n.viewChangesTitle),
		});
	}, [contentId, dialogs, intl, saveDraft, createAnalyticsEvent]);

	return (
		<DropdownItem key="view-changes" onClick={onClick} elemBefore={<ShapesIcon label="" />}>
			<FormattedMessage {...i18n.viewChangesLabel} />
		</DropdownItem>
	);
};

export const ContentOwnershipActionItem = ({
	item,
	nestedPopup,
	setNestedPopup,
	contentId,
	setIsMenuOpen,
}) => {
	const handleMenuClose = () => {
		setIsMenuOpen(false);
	};

	const handleOnClick = () => {
		if (nestedPopup === NestedMenuTypes.ContentOwnership) {
			setNestedPopup(undefined);
		} else {
			setNestedPopup(NestedMenuTypes.ContentOwnership);
		}
	};

	const handlePopupClose = () => {
		setNestedPopup(undefined);
	};

	return (
		<ContentOwnerPopup
			contentId={contentId}
			itemId={item.id}
			itemLabel={item.label}
			isOpen={nestedPopup === NestedMenuTypes.ContentOwnership}
			onClick={handleOnClick}
			onCloseParent={handleMenuClose}
			onClosePopup={handlePopupClose}
			elemBefore={fg('confluence_frontend_object_header') ? <PersonIcon label="" /> : null}
		/>
	);
};

export const getAppsMenuItem = ({
	isAppsRendered,
	spaceKey,
	contentId,
	isMenuOpen,
	nestedPopup,
	items,
	handleNestedMenuItemClick,
	createAnalyticsEvent,
	handleNestedMenuItemClose,
	closeParentMenu,
}) => {
	const pageAppItems = items
		.filter(
			(item) =>
				item?.completeKey?.includes(CONNECT_APP_PREFIX_KEY) &&
				item?.id !== ANALYTICS_ADDON_ACTIONS_MENU_ID,
		)
		.map((item) => {
			item.closeParentMenu = closeParentMenu;
			return item;
		});

	const handleAppsMenuItemClick = () => {
		handleNestedMenuItemClick(NestedMenuTypes.Apps);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'overflowApps',
				source: 'overflowMenu',
			},
		}).fire();
	};

	return {
		name: ACTION_APPS,
		shouldRender: isAppsRendered && fg('confluence_frontend_extension_points_in_live_pages'),
		component: () => (
			<Subscribe to={[DialogsStateContainer]}>
				{(dialogs) => (
					<LazyForgeContentTools
						spaceKey={spaceKey}
						contentId={contentId}
						onLoadComplete={() => {}}
						isDropdownMenuOpen={isMenuOpen}
						dialogs={dialogs}
					>
						{(forgeMenuItems) =>
							pageAppItems.length > 0 || forgeMenuItems.length > 0 ? (
								<ExpandableNestedMenuItem
									label={<FormattedMessage {...i18n.pageAppsLabel} />}
									items={pageAppItems}
									isNestedMenuOpen={nestedPopup === NestedMenuTypes.Apps}
									onClick={handleAppsMenuItemClick}
									iconBefore={<AppsIcon label="" />}
									additionalMenuItems={forgeMenuItems}
									onMenuClose={handleNestedMenuItemClose}
								/>
							) : null
						}
					</LazyForgeContentTools>
				)}
			</Subscribe>
		),
	};
};

export const AutomationsMenuItem = ({ experienceTracker, contentId, spaceKey }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const intl = useIntl();

	return (
		<Subscribe to={[DialogsStateContainer]}>
			{(dialogs: DialogsStateContainer) => (
				<DropdownItem
					elemBefore={<AutomationIcon label="" />}
					onClick={() => {
						experienceTracker.start({
							name: AUTOMATION_MANUAL_TRIGGERS,
						});
						dialogs.showModal(PageQuickActionAutomationMenu, {
							pageId: contentId,
							pageTitle: intl.formatMessage(i18n.automationsModalTitle),
							spaceKey,
						});
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'button',
								actionSubjectId: 'overflowAutomations',
								source: 'overflowMenu',
							},
						}).fire();
					}}
				>
					{intl.formatMessage(i18n.automationsLabel)}
				</DropdownItem>
			)}
		</Subscribe>
	);
};

export const AdvancedMenuItem = ({
	webItemsMap,
	handleNestedMenuItemClick,
	nestedPopup,
	handleNestedMenuItemClose,
	closeParentMenu,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const item = webItemsMap[ACTION_VIEW_STORAGE_FORMAT];

	if (!item) {
		return null;
	}

	const onClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'overflowAdvanced',
				source: 'overflowMenu',
			},
		}).fire();
		handleNestedMenuItemClick(NestedMenuTypes.Advanced);
	};

	return (
		<ExpandableNestedMenuItem
			label={<FormattedMessage {...i18n.advancedLabel} />}
			items={[
				{
					...item,
					closeParentMenu,
				},
			]}
			iconBefore={<ShowMoreIcon label="" />}
			isNestedMenuOpen={nestedPopup === NestedMenuTypes.Advanced}
			onClick={onClick}
			key={ACTION_EXPORT_CONTENT}
			onMenuClose={handleNestedMenuItemClose}
		/>
	);
};
