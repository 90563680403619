import React, { useCallback, useState, useContext, useEffect } from 'react';
import type { Dispatch } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import { useLazyQuery } from 'react-apollo';
import { Subscribe } from 'unstated';

import EditIcon from '@atlaskit/icon/core/edit';
import ShieldIcon from '@atlaskit/icon/core/shield';
import ScreenIcon from '@atlaskit/icon/core/screen';
import MegaphoneIcon from '@atlaskit/icon/core/megaphone';
import CommentIcon from '@atlaskit/icon/core/comment';
import Button from '@atlaskit/button/standard-button';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import type { OnOpenChangeArgs, CustomTriggerProps } from '@atlaskit/dropdown-menu';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Tooltip from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { ButtonItem } from '@atlaskit/menu';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import WrenchIcon from '@atlaskit/icon-lab/core/wrench';
import ChangesIcon from '@atlaskit/icon/core/changes';
import UndoIcon from '@atlaskit/icon/core/undo';
import RedoIcon from '@atlaskit/icon/core/redo';
import LockLockedIcon from '@atlaskit/icon/core/lock-locked';
import PresenterModeIcon from '@atlaskit/icon/core/presenter-mode';
import QuestionCircleIcon from '@atlaskit/icon/core/question-circle';
import ClockIcon from '@atlaskit/icon/core/clock';
import { SpotlightTarget, SpotlightTransition, SpotlightManager } from '@atlaskit/onboarding';
import FullscreenEnterIcon from '@atlaskit/icon/core/fullscreen-enter';
import LobbyBellIcon from '@atlaskit/icon/core/lobby-bell';
import Skeleton from '@atlaskit/skeleton';
import DownloadIcon from '@atlaskit/icon/core/download';
import SearchIcon from '@atlaskit/icon/core/search';

import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import type { FlagsStateContainer } from '@confluence/flags';
import { LivePagesModeChanger } from '@confluence/live-pages-features/entry-points/LivePagesModeChanger';
import { isSpaceOverview } from '@confluence/named-routes';
import {
	useRouteActions,
	createSingleQueryParamHook,
} from '@confluence/route-manager/entry-points/RouteState';
import { MoveBlogModalLoader } from '@confluence/move-blog-modal';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Buttons } from '@confluence/editor-ellipsis-dropdown/entry-points/EditorEllipsisDropdown';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { fg } from '@confluence/feature-gating';
import { DeleteNestedPagesActionLoader } from '@confluence/bulk-page-actions';
import type {
	FormattedWebItem,
	WebItemLocationQueryType,
	WebItemLocationQueryVariables,
} from '@confluence/web-item-location';
import {
	TOOLS_MENU_CONTAINER,
	WebItemLocation,
	WebItemLocationQuery,
} from '@confluence/web-item-location';
import { MovePageDialog } from '@confluence/page-tree/entry-points/MovePageDialog';
import { CopyPageTreeDialog } from '@confluence/copy-page-tree-dialog';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { useSpaceId } from '@confluence/space-utils';
import {
	ArchivePagesLoader,
	ArchiveUpsellDialog,
	UnarchivePagesDialogLoader,
} from '@confluence/archive-pages';
import { DialogsStateContainer } from '@confluence/dialogs';
import { useSessionData } from '@confluence/session-data';
import {
	ARCHIVE_PAGES_EXPERIENCE,
	DELETE_PAGE_EXPERIENCE,
	MOVE_PAGE_DIALOG_LOAD_EXPERIENCE,
	PRESENTER_MODE_VIEW_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { GlobalFeedbackCollector } from '@confluence/global-feedback-collector';
import {
	CONTENT_TYPE_FEEDBACK_SOURCE,
	useIsCommentsDialogEnabled,
	WhiteboardResolvedCommentContext,
	getIsCopyRendered,
	getIsMoveRendered,
	getIsExportRendered,
	getIsConvertRendered,
	getIsTemplatesRendered,
	getIsArchiveRendered,
	getIsPresentRendered,
	getIsSwitchToLiveEditRendered,
	getIsViewChangesRendered,
	getIsRevertRendered,
	getIsFeedbackRendered,
	getIsHelpRendered,
	getIsPreviewRendered,
	getIsContentOwnershipRendered,
	getIsClassifyContentRendered,
	getIsConvertToPageRendered,
	useIsVersionHistoryEnabled,
	getIsWatchRendered,
	getIsAutomationsRendered,
	getIsAdvancedRendered,
	getIsAppsRendered,
	getIsPageHistoryRendered,
	useIsStarringEnabled,
	useVersionHistoryExperienceTracking,
	getIsUndoRedoFindRendered,
} from '@confluence/content-types-utils';
import {
	ResolvedInlineCommentsDialogLoader,
	ResolvedInlineCommentsCounterLoader,
} from '@confluence/resolved-inline-comments-dialog';
import { CONTENT_OWNERSHIP_WEB_ITEM_ID } from '@confluence/content-ownership';
import { useSidePanelStateActions } from '@confluence/side-panel-state-container';
import { EllipsisClassificationMenuItem } from '@confluence/data-classification/entry-points/ellipsisClassification';
import { VersionHistoryOnboardingSpotlight } from '@confluence/whiteboards-onboarding';
import type { ShareContentType } from '@confluence/share';
import { LazyPresenterModeContextConsumer } from '@confluence/presentation-mode';
import {
	PRESENTATION_MODE_SHORTCUT,
	GeneralShortcutListener,
	getShortcutString,
	getShortcutStrings,
	EDITOR2_SHORTCUTS,
} from '@confluence/shortcuts';
import {
	openMoreActionsMenuClick,
	simulateItemClick,
} from '@confluence/legacy-keyboard-shortcut-helpers/entry-points/legacyKeyboardShortcutHelpers';
import { useThemingState } from '@confluence/theming/entry-points/useThemingState';
import { getSystemPreferredColorScheme } from '@confluence/theming-utils';
import {
	OBJECT_SIDEBAR_TOUR_TARGETS,
	useMoreActionsObjectSidebarTour,
	MoreActionsObjectSidebarTourSteps,
} from '@confluence/object-sidebar-components';
import { RestrictionsDialogMenuPropsProvider } from '@confluence/restrictions';
import { LazyContentToolsItem } from '@confluence/content-tools/entry-points/ContentToolsButton';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { WatchSettingsItem, LazyWatchSettingsOverflowMenuShortcut } from '@confluence/watch-dialog';
import { useRequestToEditContent } from '@confluence/no-permission/entry-points/useRequestToEditContent';
import { FavouriteMenuItem, FavouriteContentShortcutListener } from '@confluence/favourite-button';
import {
	SpaceStarMenuItemWithQuery,
	SpaceStarShortcutListener,
} from '@confluence/action-buttons/entry-points/spaceStar';
import {
	FeedbackType,
	useFeedbackCollector,
} from '@confluence/company-hub-utils/entry-points/FeedbackCollectorLoader';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';

import type { HeaderFeaturesType } from '../ContentTypesHeaderFeatures';
import { useEditorObjectHeaderContext } from '../EditorObjectHeaderContext';
import type { ContentTypesHeaderQueryNode } from '../ContentTypesHeader';

import { ConvertToLivePageItem } from './components/ConvertToLivePageMenuItem';
import { ExpandableNestedMenuItem } from './components/ExpandableNestedMenuItem';
import { MissingActionsHintMenuItem } from './components/MissingActionsHintMenuItem';
import {
	ArchiveDeleteMenuItem,
	CustomAction,
	CopyMenuItem,
	NestedDatabaseCopyMenu,
	ViewChangesItem,
	ContentOwnershipActionItem,
	NestedMenuTypes,
	ConvertMenuItem,
	ExportMenuItem,
	getAppsMenuItem,
	AutomationsMenuItem,
	ShortcutKeysVisual,
	ArchiveMenuItem,
	DeleteMenuItem,
	AdvancedMenuItem,
} from './components/SharedActionMenuItems';

export const ACTION_MOVE_CONTENT = 'action-move-page-dialog-link';
export const ACTION_MOVE_BLOGPOST = 'action-move-blogpost-dialog-link';
export const ACTION_REMOVE_CONTENT = 'action-remove-content-link';
export const ACTION_COPY_CONTENT = 'action-copy-page-link';
export const ACTION_ARCHIVE_CONTENT = 'action-archive-link';
export const ACTIONS_RESTORE_CONTENT = 'action-restore-link';
export const ACTION_GIVE_FEEDBACK = 'action-give-feedback-link';
export const ACTION_VIEW_RESOLVED_COMMENTS = 'view-resolved-comments';
export const ACTION_VIEW_VERSION_HISTORY = 'action-view-version-history-link';
export const ACTION_VIEW_PAGE_HISTORY = 'action-view-history-link';
export const ACTION_EXPORT_PDF = 'action-export-pdf-link';
export const ACTION_EXPORT_WORD = 'action-export-word-link';
export const ACTION_TEMPLATES_AND_IMPORT = 'action-templates-and-import';
export const ACTION_CONVERT_TO_BLOG = 'action-convert-page-to-blog';
export const ACTION_CONVERT_TO_FOLDER = 'action-convert-page-to-folder';
export const ACTION_CONVERT_TO_PAGE = 'action-convert-live-doc-to-page';
export const ACTION_WHITEBOARD_EXPORT = 'export-file';
export const ACTION_WHITEBOARD_FIND_REPLACE = 'open-find-replace';
export const ACTION_PRESENT_CONTENT = 'presentation-mode';
export const SWITCH_TO_LIVE_EDIT_WEB_ITEM = 'action-switch-to-live-edit';
export const ACTION_REVERT = 'action-revert-to-previous';
export const ACTION_WHITEBOARD_FULLSCREEN = 'fullscreen';
export const ACTION_WHITEBOARD_DOCUMENTATION = 'whiteboard-documentation';
export const ACTION_FAVOURITE_CONTENT = 'action-favourite-content';
export const ACTION_FAVOURITE_SPACE = 'action-favourite-space';
export const ACTION_WATCH_CONTENT = 'action-watch-content';
export const ACTION_ASK_TO_EDIT_LIVE_PAGE = 'ask-to-edit-live-page';
export const ACTION_PREVIEW_CONTENT = 'action-preview-content';
export const ACTION_CLASSIFY_CONTENT = 'action-classify-content';
export const ACTION_AUTOMATIONS = 'action-automations';
export const ACTION_ADVANCED = 'action-advanced';
export const ACTION_VIEW_STORAGE_FORMAT = 'action-view-storage-link';
export const ACTION_DATABASE_COPY_STRUCTURE = 'databases-copy-structure';
export const ACTION_DATABASE_UNDO = 'databases-undo';
export const ACTION_DATABASE_REDO = 'databases-redo';
export const ACTION_DATABASE_TEMPLATES = 'databases-templates';
export const ACTION_DATABASE_EXPORT = 'databases-export';
export const ACTION_DATABASE_LOCK_STRUCTURE = 'databases-lock-structure';
export const ACTION_DATABASE_GET_HELP = 'databases-get-help';
export const ACTION_DATABASE_DEVTOOLS = 'databases-devtools';
export const ACTION_EMBED_REPLACE_LINK = 'action-edit-embed-link';
export const ACTION_PAGES_PERMISSIONS_LINK = 'action-page-permissions-link';
export const ACTION_EXPORT_CONTENT = 'action-export-content';
export const ACTION_CONVERT_CONTENT = 'action-convert-content';
export const ACTION_COMPANY_HUB_FEEDBACK = 'action-company-hub-feedback';
export const ACTION_EDITOR_UNDO = 'action-editor-undo';
export const ACTION_EDITOR_REDO = 'action-editor-redo';
export const ACTION_EDITOR_FIND_REPLACE = 'action-editor-find-replace';
// --- Nested / Combined Actions ---
const COMBINED_ACTION_ARCHIVE = 'combined-action-archive';

const isWebItem = (item: FormattedWebItem) => {
	return typeof item === 'object' && 'id' in item;
};

const i18n = defineMessages({
	triggerLabel: {
		id: 'content-types-header.content-types.more-actions.label',
		defaultMessage: 'More actions',
		description: 'A label for the button that opens a menu providing additional actions for a page',
	},
	tooltip: {
		id: 'content-types-header.content-types.more-actions.disabled-tooltip',
		defaultMessage: 'Coming soon',
		description: 'tooltip for disabled actions',
	},
	templatesImportLabel: {
		id: 'content-types-header.ellipsis.open.ellipsis.templates',
		description: 'The label of Templates & import button inside ellipsis dropdown',
		defaultMessage: 'Templates and import',
	},
	presentLabel: {
		id: 'content-types-header.ellipsis.open.ellipsis.present',
		defaultMessage: 'Present',
		description: 'The label of the Present button inside ellipsis dropdown',
	},
	revertLabel: {
		id: 'content-types-header.revert.label',
		description: 'The label of revert to previous button inside ellipsis dropdown',
		defaultMessage: 'Revert to previous version',
	},
	help: {
		id: 'content-types-header.ellipsis.open.ellipsis.help',
		description: 'The label of the Help button inside the ellipsis dropdown',
		defaultMessage: 'Help',
	},
	previewLabel: {
		id: 'content-types-header.ellipsis.open.ellipsis.preview',
		defaultMessage: 'Preview',
		description: 'The label of the Preview button inside the ellipsis dropdown',
	},
	versionHistoryLabel: {
		id: 'content-types-header.version.history.label',
		description: 'The label of version history inside ellipsis dropdown',
		defaultMessage: 'Version history',
	},
	askToEditPageLabel: {
		id: 'content-types-header.ellipsis.open.ellipsis.ask-to-edit',
		description: 'The label for the button to request to edit a page',
		defaultMessage: 'Ask to edit',
	},
	convertToPage: {
		id: 'content-types-header.ellipsis.open.ellipsis.convert-to-page',
		description:
			'The label for an item in the overflow menu to convert a live doc into a classic page',
		defaultMessage: 'Convert to page',
	},
	companyHubFeedback: {
		id: 'content-types-header.ellipsis.open.ellipsis.hub.share-feedback',
		description: 'The label for an item in the overflow menu to provide feedback for company hub',
		defaultMessage: 'Give feedback on Company hub',
	},
	editorUndoLabel: {
		id: 'content-types-header.ellipsis.open.ellipsis.editor-undo',
		defaultMessage: 'Undo',
		description: 'The label of the Undo button inside the ellipsis dropdown',
	},
	editorRedoLabel: {
		id: 'content-types-header.ellipsis.open.ellipsis.editor-redo',
		defaultMessage: 'Redo',
		description: 'The label of the Redo button inside the ellipsis dropdown',
	},
	editorFindReplaceLabel: {
		id: 'content-types-header.ellipsis.open.ellipsis.editor-find-replace',
		defaultMessage: 'Find and replace',
		description: 'The label of the Find and replace button inside the ellipsis dropdown',
	},
});

const dropdownMenuContainerStyles = xcss({
	marginLeft: 'space.050',
	minWidth: '24px',
	display: 'flex',
	alignItems: 'center',
});

const dropdownMenuContentStyles = xcss({
	minWidth: '260px',
});

const dropdownTriggerBoxStyles = xcss({
	position: 'relative',
});

const shortcutStyles = xcss({
	backgroundColor: 'color.background.neutral',
	color: 'color.text.accent.gray',
	paddingBlock: 'space.025',
	paddingInline: 'space.075',
	borderRadius: '2px',
	margin: 'space.025',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const KeyboardShortcutIcon = styled.span({
	backgroundColor: `${token('color.background.neutral')}`,
	color: `${token('color.icon')}`,
	padding: `${token('space.025')} ${token('space.075')}`,
	borderRadius: '3px',
});

// NOTE: These styles are directly copied from ContentTools.tsx in @confluence/content-tools
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResolvedCommentsContainer = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResolvedCommentsLabelContainer = styled.div({
	flex: 2,
	marginRight: token('space.075'),
});

export interface Item {
	id: string;
	text: string;
	shortcutText?: string;
	isDisabled?: boolean;
	showToggle?: {
		isDisabled: boolean;
		isChecked?: boolean;
	};
	onClick?: () => void;
	url?: string;
	target?: string;
}

export interface ItemGroup {
	id: string;
	items: Item[];
	text?: string;
}

const isItemGroup = (item: Item | ItemGroup): item is ItemGroup => {
	return !!(item as ItemGroup).items;
};

export interface MoreActions {
	items: (Item | ItemGroup)[][];
}

type MoreActionsComponentType = {
	moreActions: MoreActions | undefined | null;
	contentId: string;
	contentType: ShareContentType;
	contentMode?: string;
	spaceKey: string;
	allowedFeatures: HeaderFeaturesType;
	hasArchivePermission: boolean | undefined;
	// Use this for onboarding spotlights in the content types header.
	handleRegisterOnShowHeaderSpotlight?: (callback: () => void) => void;
	showVersionHistorySpotlight: boolean | undefined;
	setShowVersionHistorySpotlight?: (value: boolean) => void;
	onClickOpenContentCreationPanel?: () => void;
	isContentCreationPanelOpen?: boolean;
	flags: FlagsStateContainer;
	showRevertDialog?: () => void;
	onPreviewClicked?: (...args: any[]) => void;
	hasLivePageEditPermission?: boolean;
	contentData: ContentTypesHeaderQueryNode | null | undefined;
	readOnly?: boolean;
	isConvertToFolderHighlighted?: boolean;
	setIsConvertToFolderHighlighted?: Dispatch<React.SetStateAction<boolean>>;
};

type ActionItem = {
	name: string;
	shouldRender: boolean | undefined;
	component: () => React.JSX.Element;
};

type ActionGroup = {
	key: string;
	items: ActionItem[];
};

const createActionsMap = (actions) => {
	const map = {};
	actions?.forEach((action) => {
		if (action?.id) {
			map[action.id] = action;
		}
	});
	return map;
};

const useOpenWatchMenuQueryParam = createSingleQueryParamHook('openWatchModal');

export const MoreActionsComponent = ({
	moreActions,
	contentId,
	contentType,
	contentMode,
	spaceKey,
	allowedFeatures,
	hasArchivePermission,
	showVersionHistorySpotlight,
	setShowVersionHistorySpotlight,
	flags,
	contentData,
	readOnly,
	isConvertToFolderHighlighted = false,
	setIsConvertToFolderHighlighted,
}: MoreActionsComponentType) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { requestToEditContent } = useRequestToEditContent({
		contentId,
		flags,
	});

	const {
		saveDraft,
		onClickOpenContentCreationPanel,
		isContentCreationPanelOpen,
		showRevertDialog,
		onPreviewClicked,
		hasEditPermission,
		validatePageAndOpenModal,
		canUndo: editorCanUndo,
		canRedo: editorCanRedo,
		undo: editorUndo,
		redo: editorRedo,
		activateFindReplace: editorActivateFindReplace,
	} = useEditorObjectHeaderContext();

	const openCompanyHubFeedbackCollector = useFeedbackCollector({
		source: 'hubEditorEllipsisDropdown',
		type: FeedbackType.COMPANY_HUB,
	});

	const publishPage = validatePageAndOpenModal?.('OverflowMenuConvertToPage');

	const [
		preloadWebItemsQuery,
		{ data: webItemsData, called: isWebItemsQueryCalled, error, loading },
	] = useLazyQuery<WebItemLocationQueryType, WebItemLocationQueryVariables>(WebItemLocationQuery, {
		variables: {
			contentId,
			location: TOOLS_MENU_CONTAINER,
			locations: [],
			spaceKey: null,
			version: null,
		},
	});

	const {
		showMove: shouldShowMove,
		showArchive: shouldShowArchive,
		showCopy: shouldShowCopy,
		showClassificationMenuAction: shouldShowClassification,
	} = allowedFeatures;
	const [isCopyContentModalOpen, setIsCopyContentModalOpen] = useState(false);
	const [isMoveContentModalOpen, setIsMoveContentModalOpen] = useState(false);
	const [isShortcutTriggered, setIsShortcutTriggered] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [primaryActionsCount, setPrimaryActionsCount] = useState(0);
	const [secondaryActionsCount, setSecondaryActionsCount] = useState(0);
	const [modifyActionsCount, setModifyActionsCount] = useState(0);
	const spaceId = useSpaceId();
	const { edition, isLoggedIn } = useSessionData();
	const isFreeUser = edition === ConfluenceEdition.FREE;
	const isPremiumUser = edition === ConfluenceEdition.PREMIUM;
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { trackExperience } = useVersionHistoryExperienceTracking();
	const isCommentsEnabled = useIsCommentsDialogEnabled(contentType);
	const isVersionHistoryEnabled = useIsVersionHistoryEnabled(contentType);
	const { onReopenCommentCallback } = useContext(WhiteboardResolvedCommentContext);
	const [nestedPopup, setNestedPopup] = useState<NestedMenuTypes | undefined>(undefined);
	const { showSidePanel } = useSidePanelStateActions();
	const isExternalCollaborator = useIsExternalCollaborator();
	const isSpaceOverviewPage = isSpaceOverview();
	const isPresentRendered = getIsPresentRendered(contentType, contentMode, isSpaceOverviewPage);
	const isLivePage = contentType === 'page' && contentMode === 'live';
	const [{ mode: livePageMode }] = useLivePageMode();
	const isLivePageViewOnlyPermissions = isLivePage && !hasEditPermission;
	const isPageViewOnlyPermissions =
		contentType === 'page' &&
		!contentData?.operations?.some(
			(operation) => operation?.targetType === contentType && operation?.operation === 'update',
		);
	const isStarringEnabledForContentType = useIsStarringEnabled(contentType, contentMode);
	const [{ theme }] = useThemingState();
	const openWatchModalQueryParam = Boolean(useOpenWatchMenuQueryParam());
	const { setQueryParams } = useRouteActions();
	const { activeSpotlight, isHintVisible, resetTour } = useMoreActionsObjectSidebarTour({
		step: MoreActionsObjectSidebarTourSteps.FIRST,
		isMenuOpen,
		setIsMenuOpen,
	});
	const hasReadPermission = Boolean(
		contentData?.operations?.filter(
			(operation) => operation?.targetType === contentType && operation?.operation === 'read',
		).length,
	);

	// controls the rendering of the star menu item + the star shortcut listener. There is no starring for draft pages and blogs currently.
	const shouldRenderStarContentMenuItem =
		isStarringEnabledForContentType &&
		!!allowedFeatures.showFavourite &&
		!isSpaceOverviewPage &&
		hasReadPermission;

	const shouldRenderStarSpaceMenuItem = isSpaceOverviewPage && isLoggedIn;

	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);

	const allowedEllipsisButtons = isCompanyHub
		? [Buttons.DELETE_DRAFT, Buttons.PREVIEW, Buttons.RESOLVED_COMMENTS, Buttons.VIEW_CHANGES]
		: [
				Buttons.PUBLISH_WITHOUT_NOTIFY,
				Buttons.DELETE_DRAFT,
				Buttons.EDIT_LABELS,
				Buttons.MOVE_PAGE,
				Buttons.PREVIEW,
				Buttons.RESOLVED_COMMENTS,
				Buttons.TEMPLATE_BROWSER,
				Buttons.VIEW_CHANGES,
				Buttons.SWITCH_TO_LIVE_EDIT,
				Buttons.CLASSIFY_CONTENT,
			];

	const isAllowed = (menuItem: Buttons) => allowedEllipsisButtons?.includes(menuItem) ?? true;

	useEffect(() => {
		if (showVersionHistorySpotlight && fg('confluence_frontend_wb_version_history_onboarding')) {
			setIsMenuOpen(true);
		}
		return () => setIsMenuOpen(false);
	}, [setIsMenuOpen, showVersionHistorySpotlight]);

	useEffect(() => {
		if (isConvertToFolderHighlighted) {
			setIsMenuOpen(isConvertToFolderHighlighted);
			setNestedPopup(NestedMenuTypes.Convert);
		}
	}, [isConvertToFolderHighlighted]);

	useEffect(() => {
		if (openWatchModalQueryParam && contentType && contentMode && !loading) {
			if (getIsWatchRendered(contentType, contentMode) && !!allowedFeatures.showWatchDialog) {
				setIsMenuOpen(true);
				setNestedPopup(NestedMenuTypes.Watch);
			}
			setQueryParams({ openWatchModal: null });
		}
	}, [
		openWatchModalQueryParam,
		setQueryParams,
		contentType,
		contentMode,
		loading,
		allowedFeatures,
	]);

	useEffect(() => {
		if (isShortcutTriggered && !loading) {
			if (
				webItemsData?.webItemSections?.some(
					(section) => section?.items?.length && section?.items[0]?.id === ACTION_PRESENT_CONTENT,
				)
			) {
				openMoreActionsMenuClick();
				simulateItemClick(ACTION_PRESENT_CONTENT);
			}
			setIsShortcutTriggered(false);
		}
	}, [isShortcutTriggered, webItemsData, loading]);

	const handleOpenChange = useCallback(
		({ isOpen }: OnOpenChangeArgs) => {
			// If a tour is currently in progress, prevent the menu from closing when the user clicks outside of it.
			if (activeSpotlight) return;

			setIsMenuOpen(isOpen);
			if (!isOpen) {
				resetTour();
				setNestedPopup(undefined);
				// Reset the Convert to Folder highlight state when the menu is closed
				setIsConvertToFolderHighlighted?.(false);
			}
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'contentTypeHeaderMoreActions',
					containerType: 'space',
					containerId: spaceId,
					objectId: contentId,
					source: 'contentTypesHeader',
					attributes: {
						isContentWrapper: fg('confluence_frontend_object_header'),
					},
				},
			}).fire();
		},
		[
			activeSpotlight,
			resetTour,
			createAnalyticsEvent,
			spaceId,
			contentId,
			setIsConvertToFolderHighlighted,
		],
	);

	const preloadWebItems = () => {
		void preloadWebItemsQuery();
	};

	const handleNestedMenuItemClick = (nestedMenuType: NestedMenuTypes) => {
		if (nestedPopup === nestedMenuType) {
			setNestedPopup(undefined);
		} else {
			setNestedPopup(nestedMenuType);
		}
	};

	const handleNestedMenuItemClose = () => {
		setNestedPopup(undefined);
	};

	const renderMoreActions = () => {
		if (!moreActions?.items?.length) {
			return;
		}

		return moreActions.items.map(
			(itemGroup, idx) =>
				itemGroup.length && (
					<DropdownItemGroup key={idx} hasSeparator>
						{itemGroup.map((item) => {
							if (isItemGroup(item)) {
								const { id: groupId, items: groupItems, text: groupText } = item;
								const handleOnOpenChange = ({ isOpen }: OnOpenChangeArgs) => {
									if (isOpen) {
										setNestedPopup(NestedMenuTypes.MoreSupport);
									} else {
										setNestedPopup(undefined);
									}
								};
								return (
									<DropdownMenu
										key={groupId}
										placement="left-start"
										trigger={({ triggerRef, ...triggerProps }) => (
											<ButtonItem
												{...triggerProps}
												ref={triggerRef}
												testId={`${groupId}-button`}
												iconAfter={<ChevronRightIcon color={token('color.icon.subtle')} label="" />}
											>
												<span>{groupText}</span>
											</ButtonItem>
										)}
										isOpen={nestedPopup === NestedMenuTypes.MoreSupport}
										onOpenChange={handleOnOpenChange}
									>
										{groupItems.map((subItem) => {
											const {
												id: innerId,
												url: innerUrl,
												target: innerTarget,
												onClick: innerOnClick,
												shortcutText: innerShortcutText,
												text: innerText,
											} = subItem;
											return (
												<DropdownItem
													key={innerId}
													href={innerUrl}
													target={innerTarget}
													onClick={innerOnClick}
													elemAfter={
														innerShortcutText ? (
															<KeyboardShortcutIcon>{innerShortcutText}</KeyboardShortcutIcon>
														) : undefined
													}
												>
													{innerText}
												</DropdownItem>
											);
										})}
									</DropdownMenu>
								);
							}

							return <CustomAction item={item} key={item.id} />;
						})}
					</DropdownItemGroup>
				),
		);
	};

	const onDeleteClick = useCallback(
		(dialogs: any) => {
			experienceTracker.start({
				name: DELETE_PAGE_EXPERIENCE,
			});
			dialogs.showModal(DeleteNestedPagesActionLoader, {
				contentId,
				source: 'contentTypesHeader',
				shouldRedirectOnSuccess: true,
				contentType,
			});
			setIsMenuOpen(false);
		},
		[contentId, experienceTracker, contentType],
	);

	const renderMoveContentModal = () => {
		return (
			<MovePageDialog
				contentId={contentId}
				spaceKey={spaceKey}
				onClose={() => setIsMoveContentModalOpen(false)}
				onCloseComplete={() => setIsMoveContentModalOpen(false)}
				referrer="content-types-header"
			/>
		);
	};

	const renderMovePageModal = (dialogs) => {
		experienceTracker.start({
			name: MOVE_PAGE_DIALOG_LOAD_EXPERIENCE,
		});
		dialogs.showDialog(MovePageDialog, {
			spaceKey,
			contentId,
			referrer: 'view-page-actions-menu',
			isMovingDraftVersion: contentMode === 'edit',
			isUnpublishedDraft: contentMode === 'draft',
		});
	};

	const renderMoveBlogModal = (dialogs) => {
		dialogs.showModal(MoveBlogModalLoader, {
			currentSpaceKey: spaceKey,
			blogPostId: contentId,
			isEditMode: contentMode === 'edit',
			isUnpublishedDraft: contentMode === 'draft',
		});
	};

	const renderCopyContentModal = () => {
		return (
			<CopyPageTreeDialog
				contentId={contentId}
				spaceKey={spaceKey}
				onClose={async () => setIsCopyContentModalOpen(false)}
			/>
		);
	};

	const onArchiveClick = useCallback(
		(dialogs: DialogsStateContainer) => {
			if (isFreeUser) {
				dialogs.showDialog(ArchiveUpsellDialog);
			} else {
				experienceTracker.start({ name: ARCHIVE_PAGES_EXPERIENCE });
				dialogs.showDialog(ArchivePagesLoader, {
					contentId,
					source: 'contentTypesHeader',
				});
			}
			setIsMenuOpen(false);
		},
		[isFreeUser, contentId, experienceTracker],
	);

	const handleViewVersionHistory = useCallback(() => {
		setShowVersionHistorySpotlight?.(false);
		trackExperience({
			contentType,
			experience: 'sidePanel',
			task: 'start',
		});
		showSidePanel();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'versionHistory',
				source: 'moreActionsMenu',
				objectId: contentId,
				objectType: contentType,
				attributes: {
					spotlighted: showVersionHistorySpotlight ? 1 : 0,
					isContentWrapper: fg('confluence_frontend_object_header'),
				},
			},
		}).fire();
	}, [
		contentId,
		contentType,
		createAnalyticsEvent,
		showSidePanel,
		setShowVersionHistorySpotlight,
		showVersionHistorySpotlight,
		trackExperience,
	]);

	const onRevertClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'overflowRevert',
				source: 'overflowMenu',
			},
		}).fire();

		void saveDraft?.({
			isOnClose: false,
		});

		showRevertDialog?.();
	}, [createAnalyticsEvent, saveDraft, showRevertDialog]);

	const onFeedbackClick = (dialogs: DialogsStateContainer, contentTypeForFeedback) => {
		dialogs.showModal(GlobalFeedbackCollector, {
			feedbackSource: CONTENT_TYPE_FEEDBACK_SOURCE[contentTypeForFeedback],
			showTypeField: true,
			contentType: contentTypeForFeedback,
		});
	};

	const makeInlineCommentsDialogOpener = (
		dialogs: DialogsStateContainer,
		context: 'whiteboard' | 'database',
	) => {
		dialogs.showDialog(ResolvedInlineCommentsDialogLoader, {
			contentId,
			context,
			onReopenResolvedCommentHandler: onReopenCommentCallback,
		});
	};

	const makePresenterModeOpener = (enterPresenterMode) => () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'present',
				source: 'moreActions',
				objectId: contentId,
				containerId: spaceId,
				attributes: {
					currentThemePreference: theme,
					OStheme: getSystemPreferredColorScheme(),
				},
			},
		}).fire();

		experienceTracker.start({
			name: PRESENTER_MODE_VIEW_EXPERIENCE,
		});

		enterPresenterMode();
	};

	const makePreviewContentHandler = (onPreviewClicked) => () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'previewPage',
				source: 'moreActions',
				objectId: contentId,
				objectType: contentType,
			},
		}).fire();

		onPreviewClicked(false);
	};

	const primaryActionData = ({ webItemsMap, customActionsMap }): ActionGroup => {
		const primaryActionsItems = [
			{
				name: ACTION_FAVOURITE_SPACE,
				shouldRender: shouldRenderStarSpaceMenuItem,
				component: () => <SpaceStarMenuItemWithQuery spaceKey={spaceKey} />,
			},
			{
				name: ACTION_FAVOURITE_CONTENT,
				shouldRender: shouldRenderStarContentMenuItem && !isCompanyHub,
				component: () => (
					<FavouriteMenuItem
						contentId={contentId}
						contentTitle={contentData?.title || ''}
						contentMode={contentMode || ''}
					/>
				),
			},
			{
				name: ACTION_WATCH_CONTENT,
				shouldRender:
					getIsWatchRendered(contentType, contentMode) &&
					!!allowedFeatures.showWatchDialog &&
					!isCompanyHub,
				component: () => (
					<WatchSettingsItem
						isMenuOpen={nestedPopup === NestedMenuTypes.Watch}
						contentId={contentId}
						contentType={contentType}
						spaceId={spaceId}
						spaceKey={spaceKey}
						setIsMenuOpen={setIsMenuOpen}
						onMenuClose={handleNestedMenuItemClose}
						onClick={() => handleNestedMenuItemClick(NestedMenuTypes.Watch)}
					/>
				),
			},
			{
				name: ACTION_ASK_TO_EDIT_LIVE_PAGE,
				shouldRender: isPageViewOnlyPermissions || isLivePageViewOnlyPermissions,
				component: () => (
					<ErrorBoundary attribution={Attribution.PERMISSIONS_EXPERIENCE}>
						<DropdownItem
							key={ACTION_ASK_TO_EDIT_LIVE_PAGE}
							onClick={() => requestToEditContent()}
							elemBefore={<LobbyBellIcon label="" />}
						>
							<FormattedMessage {...i18n.askToEditPageLabel} />
						</DropdownItem>
					</ErrorBoundary>
				),
			},
			{
				name: ACTION_PREVIEW_CONTENT,
				shouldRender: getIsPreviewRendered(contentType, contentMode),
				component: () => (
					<DropdownItem
						key={ACTION_PREVIEW_CONTENT}
						onClick={makePreviewContentHandler(onPreviewClicked)}
						elemBefore={<ScreenIcon label="" />}
						elemAfter={
							<ShortcutKeysVisual
								id={ACTION_PREVIEW_CONTENT}
								shortcutKeys={getShortcutStrings(EDITOR2_SHORTCUTS.PREVIEW)}
							/>
						}
					>
						<FormattedMessage {...i18n.previewLabel} />
					</DropdownItem>
				),
			},
			{
				name: ACTION_WHITEBOARD_FULLSCREEN,
				shouldRender: !!customActionsMap[ACTION_WHITEBOARD_FULLSCREEN],
				component: () => {
					const item = customActionsMap[ACTION_WHITEBOARD_FULLSCREEN];
					return <CustomAction item={item} key={item.id} icon={<FullscreenEnterIcon label="" />} />;
				},
			},
			{
				name: ACTION_PRESENT_CONTENT,
				shouldRender: isPresentRendered && !!webItemsMap[ACTION_PRESENT_CONTENT],
				component: () => (
					<ErrorBoundary
						attribution={Attribution.COMMENTS}
						key={webItemsMap[ACTION_PRESENT_CONTENT].completeKey}
					>
						<LazyPresenterModeContextConsumer
							key={`presenter-mode-menu-item-${webItemsMap[ACTION_PRESENT_CONTENT].completeKey}`}
						>
							{(enterPresenterMode) => (
								<DropdownItem
									// @ts-ignore id prop needed in order for shortcut to work properly
									id={ACTION_PRESENT_CONTENT}
									key={ACTION_PRESENT_CONTENT}
									onClick={makePresenterModeOpener(enterPresenterMode)}
									elemBefore={<PresenterModeIcon label="" />}
									elemAfter={
										isLivePage ? null : (
											<Box xcss={shortcutStyles}>
												{getShortcutString(PRESENTATION_MODE_SHORTCUT)}
											</Box>
										)
									}
								>
									<FormattedMessage {...i18n.presentLabel} />
								</DropdownItem>
							)}
						</LazyPresenterModeContextConsumer>
					</ErrorBoundary>
				),
			},
			{
				name: 'viewEditToggle',
				shouldShowSeparator: false,
				shouldRender: Boolean(
					contentMode === 'live' && hasEditPermission && fg('cc_live_pages_toggle_move'),
				),
				component: () => (
					<LivePagesModeChanger
						contentId={contentId}
						flags={flags}
						analyticsSource="livePagesEllipsisDropdown"
						elemAfter={ShortcutKeysVisual}
					/>
				),
			},
		];

		const actionsToRender = primaryActionsItems.filter((item) => item.shouldRender);

		return {
			key: 'primary-actions',
			items: actionsToRender,
		};
	};

	const primaryActions = ({ webItemsMap, customActionsMap }) => {
		const actionsToRender = primaryActionData({ webItemsMap, customActionsMap });
		setPrimaryActionsCount(actionsToRender.items.length);

		if (!actionsToRender.items.length) {
			return null;
		}

		return (
			<DropdownItemGroup key="primary-actions">
				{actionsToRender.items.map((action) => action.component())}
			</DropdownItemGroup>
		);
	};

	const modifyActionData = ({ webItems, webItemsMap, customActionsMap }): ActionGroup => {
		const hasArchive =
			!isCompanyHub &&
			!isLivePageViewOnlyPermissions &&
			getIsArchiveRendered(contentType, contentMode) &&
			(hasArchivePermission || isFreeUser) &&
			allowedFeatures.showArchive &&
			!!webItemsMap[ACTION_ARCHIVE_CONTENT];
		const hasDelete = !!webItemsMap[ACTION_REMOVE_CONTENT];

		const modifyActionsToRender = [
			{
				name: ACTIONS_RESTORE_CONTENT,
				shouldRender: !!webItemsMap[ACTIONS_RESTORE_CONTENT],
				component: () => {
					return (
						<Subscribe to={[DialogsStateContainer]} key={`container-${ACTIONS_RESTORE_CONTENT}`}>
							{(dialogs: DialogsStateContainer) => (
								<DropdownItem
									key={ACTIONS_RESTORE_CONTENT}
									onClick={() => {
										dialogs.showModal(UnarchivePagesDialogLoader, {
											contentId,
											source: 'contentTypesHeader',
										});
									}}
									elemBefore={<WrenchIcon label="" />}
								>
									{webItemsMap[ACTIONS_RESTORE_CONTENT].label}
								</DropdownItem>
							)}
						</Subscribe>
					);
				},
			},
			{
				name: ACTION_COPY_CONTENT,
				shouldRender:
					!isLivePageViewOnlyPermissions &&
					allowedFeatures.showCopy &&
					getIsCopyRendered(contentType, contentMode) &&
					(!!webItemsMap[ACTION_COPY_CONTENT] ||
						!!customActionsMap[ACTION_DATABASE_COPY_STRUCTURE]),
				component: () => {
					if (customActionsMap[ACTION_DATABASE_COPY_STRUCTURE]) {
						return (
							<NestedDatabaseCopyMenu
								isNestedMenuOpen={nestedPopup === NestedMenuTypes.CopyDatabase}
								handleClick={() => handleNestedMenuItemClick(NestedMenuTypes.CopyDatabase)}
								copyDatabaseItem={webItemsMap[ACTION_COPY_CONTENT]}
								copyStructureItem={customActionsMap[ACTION_DATABASE_COPY_STRUCTURE]}
								setIsCopyContentModalOpen={setIsCopyContentModalOpen}
								contentMode={contentMode}
								handleNestedMenuItemClose={handleNestedMenuItemClose}
								closeParentMenu={() => setIsMenuOpen(false)}
							/>
						);
					} else {
						return (
							<CopyMenuItem
								setIsCopyContentModalOpen={setIsCopyContentModalOpen}
								closeParentMenu={() => setIsMenuOpen(false)}
								item={webItemsMap[ACTION_COPY_CONTENT]}
							/>
						);
					}
				},
			},
			{
				name: ACTION_MOVE_CONTENT,
				shouldRender:
					!isLivePageViewOnlyPermissions &&
					allowedFeatures.showMove &&
					!isCompanyHub &&
					getIsMoveRendered(contentType, contentMode) &&
					!!webItemsMap[ACTION_MOVE_CONTENT],
				component: () => (
					<Subscribe to={[DialogsStateContainer]} key={`container-${ACTION_MOVE_CONTENT}`}>
						{(dialogs: DialogsStateContainer) => (
							<DropdownItem
								key={ACTION_MOVE_CONTENT}
								onClick={() => renderMovePageModal(dialogs)}
								elemBefore={<ArrowRightIcon label="" />}
							>
								{webItemsMap[ACTION_MOVE_CONTENT].label}
							</DropdownItem>
						)}
					</Subscribe>
				),
			},
			{
				name: ACTION_MOVE_BLOGPOST,
				shouldRender:
					allowedFeatures.showMove &&
					getIsMoveRendered(contentType, contentMode) &&
					!!webItemsMap[ACTION_MOVE_BLOGPOST],
				component: () => (
					<Subscribe to={[DialogsStateContainer]} key={`container-${ACTION_MOVE_BLOGPOST}`}>
						{(dialogs: DialogsStateContainer) => (
							<DropdownItem
								key={ACTION_MOVE_BLOGPOST}
								onClick={() => renderMoveBlogModal(dialogs)}
								elemBefore={<ArrowRightIcon label="" />}
							>
								{webItemsMap[ACTION_MOVE_BLOGPOST].label}
							</DropdownItem>
						)}
					</Subscribe>
				),
			},
			{
				name: ACTION_TEMPLATES_AND_IMPORT,
				shouldRender:
					getIsTemplatesRendered(contentType, contentMode, readOnly) &&
					!isContentCreationPanelOpen &&
					!isLivePageViewOnlyPermissions,
				component: () => {
					if (customActionsMap[ACTION_DATABASE_TEMPLATES]) {
						customActionsMap[ACTION_DATABASE_TEMPLATES].text = intl.formatMessage(
							i18n.templatesImportLabel,
						);
						return (
							<CustomAction
								item={customActionsMap[ACTION_DATABASE_TEMPLATES]}
								key={ACTION_DATABASE_TEMPLATES}
								icon={<DownloadIcon label="" />}
							/>
						);
					} else {
						return (
							<DropdownItem
								key={ACTION_TEMPLATES_AND_IMPORT}
								onClick={onClickOpenContentCreationPanel}
								elemBefore={<DownloadIcon label="" />}
							>
								<FormattedMessage {...i18n.templatesImportLabel} />
							</DropdownItem>
						);
					}
				},
			},
			{
				name: ACTION_CONVERT_TO_PAGE,
				shouldRender:
					getIsConvertToPageRendered(contentType, contentMode) && !isLivePageViewOnlyPermissions,
				component: () => (
					<DropdownItem
						key={ACTION_CONVERT_TO_PAGE}
						onClick={publishPage}
						elemBefore={<ChangesIcon label="" />}
					>
						<FormattedMessage {...i18n.convertToPage} />
					</DropdownItem>
				),
			},
			{
				name: ACTION_CONVERT_CONTENT,
				shouldRender:
					getIsConvertRendered(contentType, contentMode, isSpaceOverviewPage) &&
					(!!webItemsMap[ACTION_CONVERT_TO_BLOG] ||
						!!webItemsMap[ACTION_CONVERT_TO_FOLDER] ||
						!!webItemsMap[SWITCH_TO_LIVE_EDIT_WEB_ITEM]),
				component: () => (
					<ConvertMenuItem
						webItemsMap={webItemsMap}
						nestedPopup={nestedPopup}
						handleNestedMenuItemClick={handleNestedMenuItemClick}
						spaceKey={spaceKey}
						contentId={contentId}
						handleNestedMenuItemClose={handleNestedMenuItemClose}
						flags={flags}
						spaceId={spaceId}
						contentMode={contentMode}
						closeParentMenu={() => setIsMenuOpen(false)}
						isConvertToFolderHighlighted={isConvertToFolderHighlighted}
					/>
				),
			},
			{
				name: ACTION_EXPORT_CONTENT,
				shouldRender:
					getIsExportRendered(contentType, contentMode) &&
					(!!webItemsMap[ACTION_EXPORT_WORD] || !!webItemsMap[ACTION_EXPORT_PDF]),
				component: () => (
					<ExportMenuItem
						webItemsMap={webItemsMap}
						handleNestedMenuItemClick={handleNestedMenuItemClick}
						nestedPopup={nestedPopup}
						handleNestedMenuItemClose={handleNestedMenuItemClose}
						closeParentMenu={() => setIsMenuOpen(false)}
					/>
				),
			},
			{
				name: ACTION_WHITEBOARD_EXPORT,
				shouldRender: !!customActionsMap[ACTION_WHITEBOARD_EXPORT],
				component: () => {
					const item = customActionsMap[ACTION_WHITEBOARD_EXPORT];
					return <CustomAction item={item} key={item.id} icon={<ChangesIcon label="" />} />;
				},
			},
			{
				name: ACTION_WHITEBOARD_FIND_REPLACE,
				shouldRender: !!customActionsMap[ACTION_WHITEBOARD_FIND_REPLACE],
				component: () => {
					const item = customActionsMap[ACTION_WHITEBOARD_FIND_REPLACE];
					return <CustomAction item={item} key={item.id} icon={<SearchIcon label="" />} />;
				},
			},
			{
				name: ACTION_DATABASE_EXPORT,
				shouldRender: !!customActionsMap[ACTION_DATABASE_EXPORT],
				component: () => {
					const item = customActionsMap[ACTION_DATABASE_EXPORT];
					return <CustomAction item={item} key={item.id} icon={<ChangesIcon label="" />} />;
				},
			},
			{
				name: ACTION_ARCHIVE_CONTENT,
				shouldRender: hasArchive && !hasDelete,
				component: () => <ArchiveMenuItem onArchiveClick={onArchiveClick} />,
			},
			{
				name: ACTION_REMOVE_CONTENT,
				shouldRender: hasDelete && !hasArchive,
				component: () => (
					<DeleteMenuItem
						contentMode={contentMode}
						onDeleteClick={onDeleteClick}
						createAnalyticsEvent={createAnalyticsEvent}
					/>
				),
			},
			{
				name: COMBINED_ACTION_ARCHIVE,
				shouldRender: hasArchive && hasDelete,
				component: () => {
					return (
						<ArchiveDeleteMenuItem
							webItems={webItems}
							isNestedMenuOpen={nestedPopup === NestedMenuTypes.Archive}
							handleClick={() => handleNestedMenuItemClick(NestedMenuTypes.Archive)}
							onArchiveClick={onArchiveClick}
							onDeleteClick={onDeleteClick}
							contentMode={contentMode}
							createAnalyticsEvent={createAnalyticsEvent}
							handleNestedMenuItemClose={handleNestedMenuItemClose}
						/>
					);
				},
			},
		];

		const actionsToRender = modifyActionsToRender.filter((item) => item.shouldRender);

		return {
			key: 'modify-actions',
			items: actionsToRender,
		};
	};

	const modifyActions = ({ webItems, webItemsMap, customActionsMap }) => {
		const actionsToRender = modifyActionData({ webItems, webItemsMap, customActionsMap });
		setModifyActionsCount(actionsToRender.items.length);

		if (!actionsToRender.items.length) {
			return null;
		}

		const previousActionsCount =
			secondaryActionsCount > 0 ? secondaryActionsCount : primaryActionsCount;

		return (
			<DropdownItemGroup key="modify-actions" hasSeparator={previousActionsCount > 0}>
				{actionsToRender.items.map((action) => action.component())}
			</DropdownItemGroup>
		);
	};

	const secondaryActionData = ({ webItemsMap, customActionsMap, webItems }): ActionGroup => {
		const secondaryActionItems = [
			{
				name: SWITCH_TO_LIVE_EDIT_WEB_ITEM,
				shouldRender:
					getIsSwitchToLiveEditRendered(contentType, contentMode) &&
					!!webItemsMap[SWITCH_TO_LIVE_EDIT_WEB_ITEM],
				component: () => (
					<ConvertToLivePageItem
						item={webItemsMap[SWITCH_TO_LIVE_EDIT_WEB_ITEM]}
						contentMode={contentMode}
						flags={flags}
						spaceId={spaceId}
						spaceKey={spaceKey}
						contentId={contentId}
						closeParentMenu={() => setIsMenuOpen(false)}
					/>
				),
			},
			{
				name: Buttons.VIEW_CHANGES,
				shouldRender:
					!isLivePageViewOnlyPermissions &&
					getIsViewChangesRendered(contentType, contentMode) &&
					isAllowed(Buttons.VIEW_CHANGES),
				component: () => (
					<Subscribe to={[DialogsStateContainer]} key={Buttons.VIEW_CHANGES}>
						{(dialogs: DialogsStateContainer) => (
							<ViewChangesItem
								contentId={contentId}
								dialogs={dialogs}
								saveDraft={saveDraft}
								createAnalyticsEvent={createAnalyticsEvent}
							/>
						)}
					</Subscribe>
				),
			},
			{
				name: ACTION_REVERT,
				shouldRender: getIsRevertRendered(contentType, contentMode),
				component: () => (
					<DropdownItem
						key={ACTION_REVERT}
						onClick={onRevertClick}
						elemBefore={<UndoIcon label="" />}
					>
						<FormattedMessage {...i18n.revertLabel} />
					</DropdownItem>
				),
			},
			{
				name: ACTION_VIEW_RESOLVED_COMMENTS,
				shouldRender: isCommentsEnabled && !!webItemsMap[ACTION_VIEW_RESOLVED_COMMENTS],
				component: () => {
					const item = webItemsMap[ACTION_VIEW_RESOLVED_COMMENTS];
					return (
						<Subscribe to={[DialogsStateContainer]} key={`container-${item.id}`}>
							{(dialogs: DialogsStateContainer) => (
								<DropdownItem
									key={item.id}
									onClick={() => {
										if (contentType === 'whiteboard' || contentType === 'database') {
											makeInlineCommentsDialogOpener(dialogs, contentType);
										}
									}}
									elemBefore={<CommentIcon label="" />}
								>
									<ResolvedCommentsContainer>
										<ResolvedCommentsLabelContainer>{item.label}</ResolvedCommentsLabelContainer>
										<ResolvedInlineCommentsCounterLoader contentId={contentId} />
									</ResolvedCommentsContainer>
								</DropdownItem>
							)}
						</Subscribe>
					);
				},
			},
			{
				name: ACTION_PAGES_PERMISSIONS_LINK,
				shouldRender: !isExternalCollaborator && !!webItemsMap[ACTION_PAGES_PERMISSIONS_LINK],
				component: () => (
					<RestrictionsDialogMenuPropsProvider contentId={contentId}>
						{(itemProps) => (
							<LazyContentToolsItem {...itemProps} elemBefore={<LockLockedIcon label="" />} />
						)}
					</RestrictionsDialogMenuPropsProvider>
				),
			},
			{
				name: ACTION_VIEW_VERSION_HISTORY,
				shouldRender: isVersionHistoryEnabled && !!webItemsMap[ACTION_VIEW_VERSION_HISTORY],
				component: () => {
					const item = webItemsMap[ACTION_VIEW_VERSION_HISTORY];
					if (
						showVersionHistorySpotlight &&
						fg('confluence_frontend_wb_version_history_onboarding')
					) {
						return (
							<VersionHistoryOnboardingSpotlight
								key={item.id}
								contentType={contentType}
								source="moreActionsMenu"
							>
								<DropdownItem
									key={item.id}
									onClick={handleViewVersionHistory}
									elemBefore={<ClockIcon label="" />}
								>
									<FormattedMessage {...i18n.versionHistoryLabel} />
								</DropdownItem>
							</VersionHistoryOnboardingSpotlight>
						);
					} else {
						return (
							<DropdownItem
								key={item.id}
								onClick={handleViewVersionHistory}
								elemBefore={<ClockIcon label="" />}
							>
								<FormattedMessage {...i18n.versionHistoryLabel} />
							</DropdownItem>
						);
					}
				},
			},
			{
				name: ACTION_VIEW_PAGE_HISTORY,
				shouldRender:
					getIsPageHistoryRendered(contentType, contentMode) &&
					!!webItemsMap[ACTION_VIEW_PAGE_HISTORY],
				component: () => {
					const item = webItemsMap[ACTION_VIEW_PAGE_HISTORY];
					return (
						<DropdownItem
							key={item.id}
							href={item.url || ''}
							elemBefore={<ClockIcon label="" />}
							onClick={() => {
								createAnalyticsEvent({
									type: 'sendUIEvent',
									data: {
										action: 'clicked',
										actionSubject: 'button',
										actionSubjectId: 'versionHistory',
										source: 'overflowMenu',
										attributes: {
											isContentWrapper: fg('confluence_frontend_object_header'),
										},
									},
								}).fire();
							}}
						>
							<FormattedMessage {...i18n.versionHistoryLabel} />
						</DropdownItem>
					);
				},
			},
			{
				name: ACTION_DATABASE_UNDO,
				shouldRender: !!customActionsMap[ACTION_DATABASE_UNDO] && contentMode !== 'archived',
				component: () => {
					const item = customActionsMap[ACTION_DATABASE_UNDO];
					return <CustomAction item={item} key={item.id} icon={<UndoIcon label="" />} />;
				},
			},
			{
				name: ACTION_DATABASE_REDO,
				shouldRender: !!customActionsMap[ACTION_DATABASE_REDO] && contentMode !== 'archived',
				component: () => {
					const item = customActionsMap[ACTION_DATABASE_REDO];
					return <CustomAction item={item} key={item.id} icon={<RedoIcon label="" />} />;
				},
			},
			{
				name: ACTION_DATABASE_LOCK_STRUCTURE,
				shouldRender: !!customActionsMap[ACTION_DATABASE_LOCK_STRUCTURE],
				component: () => {
					const item = customActionsMap[ACTION_DATABASE_LOCK_STRUCTURE];
					return <CustomAction item={item} key={item.id} icon={<LockLockedIcon label="" />} />;
				},
			},
			{
				name: CONTENT_OWNERSHIP_WEB_ITEM_ID,
				shouldRender:
					!isLivePageViewOnlyPermissions &&
					getIsContentOwnershipRendered(contentType) &&
					!!webItemsMap[CONTENT_OWNERSHIP_WEB_ITEM_ID],
				component: () => (
					<ContentOwnershipActionItem
						key={CONTENT_OWNERSHIP_WEB_ITEM_ID}
						item={webItemsMap[CONTENT_OWNERSHIP_WEB_ITEM_ID]}
						nestedPopup={nestedPopup}
						setNestedPopup={setNestedPopup}
						contentId={contentId}
						setIsMenuOpen={setIsMenuOpen}
					/>
				),
			},
			{
				name: ACTION_CLASSIFY_CONTENT,
				shouldRender:
					!isLivePageViewOnlyPermissions &&
					!isCompanyHub &&
					getIsClassifyContentRendered(contentType) &&
					allowedFeatures.showClassificationMenuAction,
				component: () => (
					<EllipsisClassificationMenuItem
						key="classify-content"
						elemBefore={<ShieldIcon label="" />}
					/>
				),
			},
			{
				name: ACTION_AUTOMATIONS,
				shouldRender:
					isPremiumUser &&
					isLoggedIn &&
					getIsAutomationsRendered(contentType, contentMode, isSpaceOverviewPage),
				component: () => (
					<AutomationsMenuItem
						experienceTracker={experienceTracker}
						contentId={contentId}
						spaceKey={spaceKey}
					/>
				),
			},
			{
				name: ACTION_ADVANCED,
				shouldRender: getIsAdvancedRendered(contentType, contentMode),
				component: () => (
					<AdvancedMenuItem
						webItemsMap={webItemsMap}
						handleNestedMenuItemClick={handleNestedMenuItemClick}
						nestedPopup={nestedPopup}
						handleNestedMenuItemClose={handleNestedMenuItemClose}
						closeParentMenu={() => setIsMenuOpen(false)}
					/>
				),
			},
			getAppsMenuItem({
				isAppsRendered: getIsAppsRendered(contentType, contentMode),
				spaceKey,
				contentId,
				isMenuOpen,
				handleNestedMenuItemClick,
				nestedPopup,
				items: webItems,
				createAnalyticsEvent,
				handleNestedMenuItemClose,
				closeParentMenu: () => setIsMenuOpen(false),
			}),
			{
				name: ACTION_EMBED_REPLACE_LINK,
				shouldRender: !!customActionsMap[ACTION_EMBED_REPLACE_LINK],
				component: () => {
					const item = customActionsMap[ACTION_EMBED_REPLACE_LINK];
					return <CustomAction item={item} key={item.id} icon={<EditIcon label="" />} />;
				},
			},
		];

		const actionsToRender = secondaryActionItems.filter((item) => item.shouldRender);

		return {
			key: 'secondary-actions',
			items: actionsToRender,
		};
	};

	const secondaryActions = ({ webItemsMap, customActionsMap, webItems }) => {
		const actionsToRender = secondaryActionData({ webItemsMap, customActionsMap, webItems });
		setSecondaryActionsCount(actionsToRender.items.length);

		if (!actionsToRender.items.length) {
			return null;
		}

		return (
			<DropdownItemGroup key="secondary-actions" hasSeparator={primaryActionsCount > 0}>
				{actionsToRender.items.map((action) => action.component())}
			</DropdownItemGroup>
		);
	};

	const editorActionData = (): ActionGroup => {
		if (!getIsUndoRedoFindRendered(contentType, contentMode, hasEditPermission, livePageMode)) {
			return {
				key: 'editor-actions',
				items: [],
			};
		}

		const editorActionItems = [
			{
				name: ACTION_EDITOR_UNDO,
				shouldRender: !!editorUndo,
				component: () => {
					return (
						<DropdownItem
							key={ACTION_EDITOR_UNDO}
							isDisabled={!editorCanUndo}
							elemBefore={<UndoIcon label="" />}
							elemAfter={
								<ShortcutKeysVisual
									id={ACTION_EDITOR_UNDO}
									shortcutKeys={getShortcutStrings(EDITOR2_SHORTCUTS.UNDO)}
								/>
							}
							onClick={async () => {
								editorUndo?.();
							}}
						>
							<FormattedMessage {...i18n.editorUndoLabel} />
						</DropdownItem>
					);
				},
			},
			{
				name: ACTION_EDITOR_REDO,
				shouldRender: !!editorRedo,
				component: () => {
					return (
						<DropdownItem
							key={ACTION_EDITOR_REDO}
							isDisabled={!editorCanRedo}
							elemBefore={<RedoIcon label="" />}
							elemAfter={
								<ShortcutKeysVisual
									id={ACTION_EDITOR_REDO}
									shortcutKeys={getShortcutStrings(EDITOR2_SHORTCUTS.REDO)}
								/>
							}
							onClick={async () => {
								editorRedo?.();
							}}
						>
							<FormattedMessage {...i18n.editorRedoLabel} />
						</DropdownItem>
					);
				},
			},
			{
				name: ACTION_EDITOR_FIND_REPLACE,
				shouldRender: !!editorActivateFindReplace,
				component: () => {
					return (
						<DropdownItem
							key={ACTION_EDITOR_FIND_REPLACE}
							elemBefore={<SearchIcon label="" />}
							elemAfter={
								<ShortcutKeysVisual
									id={ACTION_EDITOR_FIND_REPLACE}
									shortcutKeys={getShortcutStrings(EDITOR2_SHORTCUTS.FIND_REPLACE)}
								/>
							}
							onClick={async () => {
								editorActivateFindReplace?.();
							}}
						>
							<FormattedMessage {...i18n.editorFindReplaceLabel} />
						</DropdownItem>
					);
				},
			},
		];

		const actionsToRender = editorActionItems.filter((item) => item.shouldRender);
		return {
			key: 'editor-actions',
			items: actionsToRender,
		};
	};

	const feedbackActionData = ({ webItemsMap, customActionsMap, actions }): ActionGroup => {
		const additionalActionItems = [
			{
				name: ACTION_GIVE_FEEDBACK,
				shouldRender: getIsFeedbackRendered(contentType) && !!webItemsMap[ACTION_GIVE_FEEDBACK],
				component: () => {
					return (
						<Subscribe to={[DialogsStateContainer]}>
							{(dialogs: DialogsStateContainer) => (
								<DropdownItem
									key={ACTION_GIVE_FEEDBACK}
									onClick={() => {
										onFeedbackClick(dialogs, contentType);
									}}
									elemBefore={<MegaphoneIcon label="" />}
								>
									{webItemsMap[ACTION_GIVE_FEEDBACK].label}
								</DropdownItem>
							)}
						</Subscribe>
					);
				},
			},
			{
				name: ACTION_WHITEBOARD_DOCUMENTATION,
				shouldRender:
					getIsHelpRendered(contentType) && !!customActionsMap[ACTION_WHITEBOARD_DOCUMENTATION],
				component: () => {
					const items = actions.flat().map((action) => ({
						...action,
						customComponent: () => <CustomAction item={action} key={action.id} />,
					}));

					return (
						<ExpandableNestedMenuItem
							label={<FormattedMessage {...i18n.help} />}
							isNestedMenuOpen={nestedPopup === NestedMenuTypes.Help}
							onClick={() => handleNestedMenuItemClick(NestedMenuTypes.Help)}
							iconBefore={<QuestionCircleIcon label="" />}
							items={items}
							onMenuClose={handleNestedMenuItemClose}
						/>
					);
				},
			},
			{
				name: ACTION_DATABASE_GET_HELP,
				shouldRender:
					getIsHelpRendered(contentType) && !!customActionsMap[ACTION_DATABASE_GET_HELP],
				component: () => {
					customActionsMap[ACTION_DATABASE_GET_HELP].text = intl.formatMessage(i18n.help);
					const item = customActionsMap[ACTION_DATABASE_GET_HELP];
					return <CustomAction item={item} key={item.id} icon={<QuestionCircleIcon label="" />} />;
				},
			},
			{
				name: ACTION_DATABASE_DEVTOOLS,
				shouldRender: !!customActionsMap[ACTION_DATABASE_DEVTOOLS],
				component: () => {
					const item = customActionsMap[ACTION_DATABASE_DEVTOOLS];
					return <CustomAction item={item} key={item.id} />;
				},
			},
			{
				name: ACTION_COMPANY_HUB_FEEDBACK,
				shouldRender: isCompanyHub,
				component: () => (
					<DropdownItem
						key={ACTION_COMPANY_HUB_FEEDBACK}
						onClick={openCompanyHubFeedbackCollector}
						elemBefore={<MegaphoneIcon label="" />}
					>
						<FormattedMessage {...i18n.companyHubFeedback} />
					</DropdownItem>
				),
			},
		];

		const actionsToRender = additionalActionItems.filter((item) => item.shouldRender);

		return {
			key: 'additional-items',
			items: actionsToRender,
		};
	};

	const feedbackActions = ({ webItemsMap, customActionsMap, actions }) => {
		const actionsToRender = feedbackActionData({ webItemsMap, customActionsMap, actions });

		if (!actionsToRender.items.length) {
			return null;
		}

		let previousActionsCount;
		if (modifyActionsCount > 0) {
			previousActionsCount = modifyActionsCount;
		} else if (secondaryActionsCount > 0) {
			previousActionsCount = secondaryActionsCount;
		} else {
			previousActionsCount = primaryActionsCount;
		}

		return (
			<DropdownItemGroup hasSeparator={previousActionsCount > 0} key="additional-items">
				{actionsToRender.items.map((action) => action.component())}
			</DropdownItemGroup>
		);
	};

	const renderModifyContentActionsWebItems = (webItems: FormattedWebItem[]) => {
		return (
			<DropdownItemGroup>
				{webItems.map((item) => {
					if (isWebItem(item)) {
						if (shouldShowCopy && item?.id === ACTION_COPY_CONTENT) {
							return (
								<DropdownItem key={item.id} onClick={() => setIsCopyContentModalOpen(true)}>
									{item.label}
								</DropdownItem>
							);
						}

						if (shouldShowMove && item?.id === ACTION_MOVE_CONTENT) {
							return (
								<DropdownItem key={item.id} onClick={() => setIsMoveContentModalOpen(true)}>
									{item.label}
								</DropdownItem>
							);
						}

						if (item?.id === ACTIONS_RESTORE_CONTENT) {
							return (
								<Subscribe to={[DialogsStateContainer]} key={`container-${item.id}`}>
									{(dialogs: DialogsStateContainer) => (
										<DropdownItem
											key={item.id}
											onClick={() => {
												dialogs.showDialog(UnarchivePagesDialogLoader, {
													contentId,
													source: 'contentTypesHeader',
												});
											}}
										>
											{item.label}
										</DropdownItem>
									)}
								</Subscribe>
							);
						}

						if (item?.id === ACTION_REMOVE_CONTENT) {
							return (
								<Subscribe to={[DialogsStateContainer]} key={`container-${item.id}`}>
									{(dialogs: DialogsStateContainer) => (
										<DropdownItem
											key={item.id}
											onClick={() => {
												onDeleteClick(dialogs);
											}}
										>
											{item.label}
										</DropdownItem>
									)}
								</Subscribe>
							);
						}

						if (item?.id === ACTION_ARCHIVE_CONTENT && hasArchivePermission && shouldShowArchive) {
							return (
								<Subscribe to={[DialogsStateContainer]} key={`container-${item.id}`}>
									{(dialogs: DialogsStateContainer) => (
										<DropdownItem
											key={item.id}
											onClick={() => {
												onArchiveClick(dialogs);
											}}
										>
											{item.label}
										</DropdownItem>
									)}
								</Subscribe>
							);
						}
					} else {
						return (
							<Tooltip
								content={<FormattedMessage {...i18n.tooltip} />}
								position="left"
								key={item.id}
							>
								<DropdownItem isDisabled>{item.label}</DropdownItem>
							</Tooltip>
						);
					}

					return null;
				})}
			</DropdownItemGroup>
		);
	};

	const renderPrimaryContentActionsWebItems = (webItems: FormattedWebItem[]) => {
		const webItemsToRender = webItems
			.map((item) => {
				if (isWebItem(item)) {
					if (
						item?.id === ACTION_VIEW_RESOLVED_COMMENTS &&
						isCommentsEnabled &&
						(contentType === 'whiteboard' || contentType === 'database')
					) {
						return (
							<Subscribe to={[DialogsStateContainer]} key={`container-${item.id}`}>
								{(dialogs: DialogsStateContainer) => (
									<DropdownItem
										key={item.id}
										onClick={() => makeInlineCommentsDialogOpener(dialogs, contentType)}
									>
										<ResolvedCommentsContainer>
											<ResolvedCommentsLabelContainer>{item.label}</ResolvedCommentsLabelContainer>
											<ResolvedInlineCommentsCounterLoader contentId={contentId} />
										</ResolvedCommentsContainer>
									</DropdownItem>
								)}
							</Subscribe>
						);
					}

					if (item?.id === ACTION_VIEW_VERSION_HISTORY && isVersionHistoryEnabled) {
						if (
							showVersionHistorySpotlight &&
							fg('confluence_frontend_wb_version_history_onboarding')
						) {
							return (
								<VersionHistoryOnboardingSpotlight
									key={item.id}
									contentType={contentType}
									source="moreActionsMenu"
								>
									<DropdownItem key={item.id} onClick={handleViewVersionHistory}>
										{item.label}
									</DropdownItem>
								</VersionHistoryOnboardingSpotlight>
							);
						} else {
							return (
								<DropdownItem key={item.id} onClick={handleViewVersionHistory}>
									{item.label}
								</DropdownItem>
							);
						}
					}

					if (item?.id === CONTENT_OWNERSHIP_WEB_ITEM_ID) {
						return (
							<ContentOwnershipActionItem
								key={`container-${item.id}`}
								item={item}
								nestedPopup={nestedPopup}
								setNestedPopup={setNestedPopup}
								contentId={contentId}
								setIsMenuOpen={setIsMenuOpen}
							/>
						);
					}
				}
				return null;
			})
			.filter(Boolean);

		const itemsToRender = shouldShowClassification
			? [<EllipsisClassificationMenuItem key="classify-content" />, ...webItemsToRender]
			: webItemsToRender;

		return itemsToRender.length > 0 ? (
			<DropdownItemGroup hasSeparator>{itemsToRender}</DropdownItemGroup>
		) : null;
	};

	const renderFeedbackWebItems = (webItems: FormattedWebItem[]) => {
		// If additional WebItems are added to the feedback WebSection in the monolith,
		// follow the structure of renderModifyContentActionsWebItems to map items into a collective
		// DropdownItemGroup rather than an individual group
		return webItems.map((item) => {
			if (isWebItem(item)) {
				if (
					item?.id === ACTION_GIVE_FEEDBACK &&
					contentType &&
					CONTENT_TYPE_FEEDBACK_SOURCE[contentType]
				) {
					return (
						<DropdownItemGroup hasSeparator key={`container-${item.id}`}>
							<Subscribe to={[DialogsStateContainer]}>
								{(dialogs: DialogsStateContainer) => (
									<DropdownItem
										key={item.id}
										onClick={() => {
											onFeedbackClick(dialogs, contentType);
										}}
									>
										{item.label}
									</DropdownItem>
								)}
							</Subscribe>
						</DropdownItemGroup>
					);
				}
			}

			return null;
		});
	};

	const renderActionsOld = ({ webItems }: { webItems: FormattedWebItem[] }) => {
		const webItemsMap = createActionsMap(webItems);

		const customActionsToRender = moreActions?.items?.flat() ?? [];
		const customActionsMap = createActionsMap(customActionsToRender);
		const [, , , feedbackCustomActions] = moreActions?.items ?? [];

		return (
			<Box xcss={dropdownMenuContentStyles}>
				{primaryActions({
					webItemsMap,
					customActionsMap,
				})}
				{secondaryActions({
					webItemsMap,
					customActionsMap,
					webItems,
				})}
				{modifyActions({
					webItems,
					webItemsMap,
					customActionsMap,
				})}
				{feedbackActions({
					webItemsMap,
					customActionsMap,
					actions: feedbackCustomActions ?? [],
				})}
				{isHintVisible && !isCompanyHub && (
					<MissingActionsHintMenuItem setIsMenuOpen={setIsMenuOpen} />
				)}
			</Box>
		);
	};

	const renderActionsNew = ({ webItems }: { webItems: FormattedWebItem[] }) => {
		const webItemsMap = createActionsMap(webItems);

		const customActionsToRender = moreActions?.items?.flat() ?? [];
		const customActionsMap = createActionsMap(customActionsToRender);
		const [, , , feedbackCustomActions] = moreActions?.items ?? [];

		const actionGroupsToRender: ActionGroup[] = [];
		const primaryActionGroup = primaryActionData({
			webItemsMap,
			customActionsMap,
		});
		if (primaryActionGroup.items.length > 0) {
			actionGroupsToRender.push(primaryActionGroup);
		}
		const editorActionGroup = editorActionData();
		if (editorActionGroup.items.length > 0) {
			actionGroupsToRender.push(editorActionGroup);
		}
		const secondaryActionGroup = secondaryActionData({
			webItemsMap,
			customActionsMap,
			webItems,
		});
		if (secondaryActionGroup.items.length > 0) {
			actionGroupsToRender.push(secondaryActionGroup);
		}
		const modifyActionGroup = modifyActionData({
			webItems,
			webItemsMap,
			customActionsMap,
		});
		if (modifyActionGroup.items.length > 0) {
			actionGroupsToRender.push(modifyActionGroup);
		}
		const feedbackActionGroup = feedbackActionData({
			webItemsMap,
			customActionsMap,
			actions: feedbackCustomActions ?? [],
		});
		if (feedbackActionGroup.items.length > 0) {
			actionGroupsToRender.push(feedbackActionGroup);
		}

		return (
			<Box xcss={dropdownMenuContentStyles}>
				{actionGroupsToRender.map((actionGroup, index) => (
					<DropdownItemGroup hasSeparator={index > 0} key={actionGroup.key}>
						{actionGroup.items.map((item) => item.component())}
					</DropdownItemGroup>
				))}
				{isHintVisible && !isCompanyHub && (
					<MissingActionsHintMenuItem setIsMenuOpen={setIsMenuOpen} />
				)}
			</Box>
		);
	};

	const renderActions = fg('confluence_frontend_more_action_refactor')
		? renderActionsNew
		: renderActionsOld;

	return (
		<>
			{error && <ErrorDisplay error={error} />}
			{isPresentRendered && !isLivePage && (
				<GeneralShortcutListener
					accelerator={PRESENTATION_MODE_SHORTCUT}
					listener={() => {
						if (!isWebItemsQueryCalled) {
							preloadWebItems();
						}
						setIsShortcutTriggered(true);
					}}
				/>
			)}
			{shouldRenderStarContentMenuItem && (
				<FavouriteContentShortcutListener
					contentId={contentId}
					contentTitle={contentData?.title || ''}
					contentMode={contentMode}
					contentType={contentType}
				/>
			)}
			{shouldRenderStarSpaceMenuItem && (
				<SpaceStarShortcutListener spaceKey={spaceKey} contentMode={contentMode} />
			)}
			{getIsWatchRendered(contentType, contentMode) && fg('confluence_frontend_object_header') && (
				<LazyWatchSettingsOverflowMenuShortcut
					contentId={contentId}
					contentType={contentType}
					openWatchSettingsMenu={() => {
						if (!isMenuOpen) {
							setIsMenuOpen(true);
						}
						setNestedPopup(NestedMenuTypes.Watch);
					}}
				/>
			)}
			<Box
				xcss={[!fg('confluence_frontend_object_header') && dropdownMenuContainerStyles]}
				onMouseEnter={preloadWebItems}
			>
				<DropdownMenu
					isOpen={isMenuOpen}
					onOpenChange={handleOpenChange}
					trigger={(triggerProps) => <DropdownMenuTrigger triggerProps={triggerProps} />}
					placement="bottom-end"
				>
					<WebItemLocation
						data-testid="web-actions-test"
						contentId={contentId}
						location={TOOLS_MENU_CONTAINER}
						fetchPolicy="cache-and-network"
						renderWhenLoading={fg('confluence_frontend_object_header')}
					>
						{({ webItems }) => {
							if (fg('confluence_frontend_object_header')) {
								if (loading) {
									return <DropdownMenuContentSkeleton />;
								}
								return (
									<>
										<SpotlightManager>
											<SpotlightTarget name={OBJECT_SIDEBAR_TOUR_TARGETS.MORE_ACTIONS_ITEMS}>
												{renderActions({ webItems })}
											</SpotlightTarget>
											<SpotlightTransition>{activeSpotlight}</SpotlightTransition>
										</SpotlightManager>
									</>
								);
							} else {
								return (
									<Box padding="space.025">
										{renderModifyContentActionsWebItems(webItems)}
										{renderPrimaryContentActionsWebItems(webItems)}
										{renderFeedbackWebItems(webItems)}
										{renderMoreActions()}
									</Box>
								);
							}
						}}
					</WebItemLocation>
				</DropdownMenu>
			</Box>
			{isCopyContentModalOpen && renderCopyContentModal()}
			{isMoveContentModalOpen && renderMoveContentModal()}
		</>
	);
};

interface DropdownMenuTriggerProps {
	triggerProps: CustomTriggerProps<HTMLElement>;
}

const DropdownMenuTrigger: React.FC<DropdownMenuTriggerProps> = ({ triggerProps }) => {
	const { triggerRef, ...props } = triggerProps;
	const intl = useIntl();

	return (
		<Box xcss={dropdownTriggerBoxStyles}>
			<Tooltip position="bottom" content={<FormattedMessage {...i18n.triggerLabel} />}>
				<Button
					{...props}
					iconBefore={
						<ShowMoreHorizontalIcon
							spacing="spacious"
							label={intl.formatMessage(i18n.triggerLabel)}
						/>
					}
					ref={triggerRef}
					appearance="subtle"
					id="more-actions-trigger"
				/>
			</Tooltip>
		</Box>
	);
};

const DropdownMenuContentSkeleton = () => (
	<>
		<DropdownItemSkeleton />
		<DropdownItemSkeleton />
		<DropdownItemSkeleton />
		<DropdownItemSkeleton />
		<DropdownItemSkeleton />
		<DropdownItemSkeleton />
		<DropdownItemSkeleton />
		<DropdownItemSkeleton />
		<DropdownItemSkeleton />
		<DropdownItemSkeleton />
	</>
);

const DropdownItemSkeleton = () => (
	<DropdownItem>
		<Skeleton isShimmering borderRadius={0} width={200} height={20} />
	</DropdownItem>
);
