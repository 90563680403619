import { defineMessages } from 'react-intl-next';

const i18n = defineMessages({
	shortJustNow: {
		id: 'content-types-header.last-edited.shortJustNow',
		description:
			'Time string in last edited shortened header timestamp that indicates last change was just now',
		defaultMessage: 'Just now',
	},
	shortXMinutesAgo: {
		id: 'content-types-header.last-edited.shortXMinutesAgo',
		description:
			'Time string in last edited shortened header timestamp that indicates last change was x minutes ago',
		defaultMessage: '{timeAgo}m ago',
	},
	shortXHoursAgo: {
		id: 'content-types-header.last-edited.shortXHoursAgo',
		description:
			'Time string in last edited shortened header timestamp that indicates last change was x hours ago',
		defaultMessage: '{timeAgo}h ago',
	},
	shortXDaysAgo: {
		id: 'content-types-header.last-edited.shortXDaysAgo',
		description:
			'Time string in last edited shortened header timestamp that indicates last change was x days ago',
		defaultMessage: '{timeAgo}d ago',
	},
	tooltipFullDate: {
		id: 'content-types-header.last-edited.tooltipFullDate',
		description:
			'Expanded time string in last edited for tooltip that indicates last change was on (date) at (time)',
		defaultMessage: '{date} at {time}',
	},
	tooltipDate: {
		id: 'content-types-header.last-edited.tooltipDate',
		description:
			'Expanded time string in last edited for tooltip that indicates last change was >2 days ago on (date)',
		defaultMessage: '{date}',
	},
});

const MILLISECONDS_TO_SECONDS = 1000;
const MILLISECONDS_TO_MINUTES = 60 * 1000;
const MILLISECONDS_TO_HOUR = 60 * 60 * 1000;
const MILLISECONDS_TO_DAY = 24 * 60 * 60 * 1000;

/**
 * Formats full server date in shortened form ('MMM DD') if in the same calendar year, otherwise uses long form ('MMM DD, YYYY)
 */
const formatDateUTC = (fullServerDate: string) => {
	const now = new Date();
	const currentYear = now.getUTCFullYear();

	if (fullServerDate.includes(currentYear.toString())) {
		// We expect that full server date (version.friendlyWhen) is of the format 'MMM DD, YYYY' (Jan 01, 2025) format for US_en
		// If it is not in this format depending on localization, we take the entire date
		const splitDate = fullServerDate.split(',');
		return splitDate.length === 2 ? splitDate[0] : fullServerDate;
	}

	return fullServerDate;
};

/**
 * Takes lastEditedTime as Date object and converts to shortened relative friendly timestamp format for any relative timeframe.
 * For > 2 days uses server timestamp to satisfy hydration and SSR
 */
export const lastEditedHeaderFriendlyFormatter = (
	lastEditedTime: Date,
	fullServerDate: string,
	formatMessage: any,
) => {
	let headerTimestamp: string;
	const timeNow = new Date();
	const timeDifference = timeNow.getTime() - lastEditedTime.getTime();

	if (timeDifference / MILLISECONDS_TO_SECONDS < 60) {
		headerTimestamp = formatMessage(i18n.shortJustNow);
	} else if (timeDifference / MILLISECONDS_TO_MINUTES < 60) {
		const minutes = Math.floor(timeDifference / MILLISECONDS_TO_MINUTES);
		headerTimestamp = formatMessage(i18n.shortXMinutesAgo, { timeAgo: minutes });
	} else if (timeDifference / MILLISECONDS_TO_HOUR < 24) {
		const hours = Math.floor(timeDifference / MILLISECONDS_TO_HOUR);
		headerTimestamp = formatMessage(i18n.shortXHoursAgo, { timeAgo: hours });
	} else if (timeDifference / MILLISECONDS_TO_HOUR < 48) {
		const days = Math.floor(timeDifference / MILLISECONDS_TO_DAY);
		headerTimestamp = formatMessage(i18n.shortXDaysAgo, { timeAgo: days });
	} else {
		// After 48 hours, include date in format MMM DD (shortened month 'Jan', for US_en) from server
		return formatDateUTC(fullServerDate);
	}

	return headerTimestamp;
};

/**
 * Takes lastEditedTime as Date object and converts to expanded tooltip timestamp format.
 */
export const lastEditedTooltipTimestampFormatter = (
	lastEditedTime: Date,
	fullServerDate: string,
	formatMessage: any,
	formatTime: any,
	formatDate: any,
) => {
	let tooltipTimestamp: string;
	const timeNow = new Date();
	const timeDifference = timeNow.getTime() - lastEditedTime.getTime();
	// Format client timestamp for use when < 48 hours in the past
	const monthAndDay = formatDate(lastEditedTime, {
		month: 'short',
		day: 'numeric',
	});
	const formattedTime = formatTime(lastEditedTime, {
		hour: '2-digit',
		minute: 'numeric',
	});

	if (timeDifference / MILLISECONDS_TO_HOUR < 48) {
		tooltipTimestamp = formatMessage(i18n.tooltipFullDate, {
			date: monthAndDay,
			time: formattedTime,
		});
	} else {
		// Use server friendlyWhen here because we want it to match the header timestamp
		tooltipTimestamp = formatMessage(i18n.tooltipDate, { date: fullServerDate });
	}

	return tooltipTimestamp;
};
